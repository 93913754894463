import React from "react";
import Card from "react-bootstrap/Card";
import Loading from "../Loading";
import { Link } from "react-router-dom";

export default function ConversationDisplay({app, limit=15}) {
  // Variables that control how many comments are returned from the app
  // const [limit, setLimit] = React.setState(5);
  // const [skip, setSkip] = React.setState(0); 

  // const limit = 5;
  // const skip = 0;
  return (
    <ApiResults app={app} limit={limit}/>
  );
}

function ApiResults({app, limit}) {
  const [conversationsData, setConversationsData] = React.useState("");

  React.useEffect(() => {
    app.currentUser.functions.getConversations(
      limit
    )
    .then((response) => {
      setConversationsData(response);
    })
  }, []);

  if (conversationsData) {
    return (
      <Conversations
        conversations={conversationsData} 
      />
    );
  } else {
    return (
      <Loading />
    );
  }
}

function Conversations({conversations}) {
  // Return message to user if there are no conversations
  if (conversations.length < 1) {
    return (
      <div className="h-50 px-3 mt-3 text-center">
        It's quiet...<br />
        Comment on a page in a model to<br />
        start a conversation
      </div>
    )
  }
  return (
    conversations.map((conversation, i) => (
      <Conversation conversation={conversation} key={i} />
    ))
  );
}

function Conversation({conversation, key}) {
  return (
    <Card className="py-3 border-top-0 border-right-0 border-left-0 border-bottom-1">
      <ConversationTitle object={conversation.object} />
      <Comments comments={conversation.comments}/>
    </Card>
  );
}

function ConversationTitle({object}) {
  
  const collection = object.collection.replace("entity", "").toLowerCase(); // remove entity prefix (entitySpace -> space)
  const linkString = `/${collection}/${object.objectId}`;
  let conversationParentLabel = object.parentName;
  if (object.parentLongName) {
    conversationParentLabel = object.parentLongName;
  }
  let conversationLabel = object.entityName;
  if (object.entityLongName) {
    conversationLabel = object.entityLongName;
  }

  return (
    <Link to={linkString}>
      <small className="text-muted">{conversationParentLabel}</small><br />
      {conversationLabel}
    </Link>
  );
}

function Comments({comments}) {
  return (
    comments.map((comment, i) => (
      <Comment comment={comment} key={i} />
    ))
  );
}

function Comment({comment, key}) {
  return (
    <Card className="mt-2 border-0">
      <CommentUser comment={comment} key={key} />
      <CommentText comment={comment} key={key} />
    </Card>
  );
}

function CommentUser({comment, key}) {
  // returns a formatted span containing the user name of the commenter
  const username = comment.user;
  return (
    <small>
      {username} 
    </small>
  );
}

// function CommentPostTime() {
//   // returns the time that has elapsed from the user's current time since the time the comment was posted
//   return null;
// }

function CommentText({comment}) {
  return (
    comment.comment
  );
}

