import React from "react";
import { useParams } from "react-router-dom";
import { useRealmApp } from "../../RealmApp";
import Loading from "../Loading";
import ErrorBoundary from "../ErrorBoundary";
import EntityHeaderCard from "../cards/EntityHeaderCard";
import RelatedListCard from "../cards/RelatedListCard";
import RelatedEntitiesCard from "../cards/RelatedEntitiesCard";
import CommentsCard from "../cards/CommentsCard";
import GeoJsonViewerCard from "../cards/GeoJsonViewerCard";

export default function BoundaryEntityScreen() {
  const app = useRealmApp();
  const params = useParams();
  
  var props = null;
  if (params.p) {
    props = {
      "objectId": "",
      "versionId": params.p,
      "globalId": params.id
    }
  } else {
    props = {
      "objectId": params.id,
      "versionId": "",
      "globalId": ""
    }
  }

  return (
    <div>
      <ErrorBoundary>
        <ApiResults 
          app={app}
          props={props}
        />
      </ErrorBoundary>
    </div>
  );
}

function ApiResults({app, props}) {

  const [responseData, setResponseData] = React.useState("");

  React.useEffect(() => {
    app.currentUser.functions.getDocument(
      'entityBoundary',
      props.objectId,
      props.versionId,
      props.globalId
    )
    .then((response) => {
      console.log("API Call - Boundary Entity");
      setResponseData(response);
    })

  }, [props, app.currentUser.functions]);

  if (responseData) {
    console.log("Loading Boundary Entity Cards");
    return (
      <BoundaryEntityCardLayout 
        entity={responseData.results} 
      />
    );
  } else {
    return (
      <Loading />
    );
  }
}

function BoundaryEntityCardLayout ({entity}) {
  var entityProperties = {};
  let relatedLists = []
  if (Object.keys(entity).includes("isDefinedBy")) {
    entityProperties["propertySets"] = getPropertySetList(entity);
  }
  if (Object.keys(entity).includes("containedInStructure")) {
    entityProperties["containedInStructure"] = getContainedInStructure(entity);
    if (entity["containedInStructure"]) {
      relatedLists.push([entity["containedInStructure"]]);
    }
  }
  if (Object.keys(entity).includes("providesBoundaries")) {
    // entityProperties["providesBoundaries"] = getProvidesBoundaries(entity);
    if (entity["providesBoundaries"]) {
      relatedLists.push(entity["providesBoundaries"]);
    }
  }
  if (Object.keys(entity).includes("connectedTo") || Object.keys(entity).includes("connectedFrom")) {
    entityProperties["connectedEntities"] = getConnectedEntitiesList(entity);
    // if (entity["connectedEntities"]) {
    //   relatedLists.push(entity["connectedEntities"]);
    // }
  }
  // try {
  //  entityProperties["materialAssociations"] = getMaterialAssociations(entity);
  // } catch (e) {
  //   console.log("Material Associations not found")
  // }
  
  // const entityProperties = {connectedEntities, containedInStructure};
  
  entity.representation = null; // Suppresses viewer | Trying to avoid artifacts during demo

  if (entity.geoJson) {
    return (
      <div className="w-75 mx-auto">
        <div className="row">
          <EntityHeaderCard 
            parentEntityName={entity.project.name}
            parentEntityLongName={entity.project.longName}
            entityName={entity.name} 
            entityLongName={entity.longName}
          />
        </div>

        <div className="row">
          <div className="col-9">
            <div className="row">
              <div className="col-12">
                <GeoJsonViewerCard
                  geoJson={entity.geoJson}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-3">
                <RelatedListCard versionId={entity.project.versionId} relatedLists={relatedLists} />
              </div>
              <div className="col-9">
                <RelatedEntitiesCard
                  entityProperties={entityProperties}
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <CommentsCard objectId={entity._id.toString()} collectionName="entitySpace" />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-75 mx-auto">
        <EntityHeaderCard 
          parentEntityName={entity.project.name}
          parentEntityLongName={entity.project.longName}
          entityLongName={entity.name}
        />
        <div className="row">
          <div className="col-3">
            <RelatedListCard versionId={entity.project.versionId} relatedLists={relatedLists}/>
          </div>
          <div className="col-6">
            <RelatedEntitiesCard
              entityProperties={entityProperties}
            />
          </div>
          <div className="col-3">
              <CommentsCard objectId={entity._id.toString()} collectionName="entityBoundary" />
            </div>
        </div>
      </div>
    );
  }
  
}

function getProvidesBoundaries(entity) {
  let providesBoundaries = {}

  if (Object.keys(entity).includes("providesBoundaries")) {
    for (var i=0; i<entity.providesBoundaries.length; i++) {
      const entityType = entity.providesBoundaries[i].type;
      // const entityName = entity.providesBoundaries[i].name; // Change this to name once the names have been added
      console.log(entityType)
      // console.log(`${Object.keys(connectedFrom).includes(entityType)} | ${entityType} in ${Object.keys(connectedFrom)}`);
      if (Object.keys(providesBoundaries).includes(entityType)) {
        providesBoundaries[entityType].push({
          "name": entity.providesBoundaries[i].name, 
          "longName": entity.providesBoundaries[i].longName,
          "type": "entitySpace", 
          "versionId": entity.project.versionId,
          "globalId": entity.providesBoundaries[i].globalId
        });
      } else {
        providesBoundaries[entityType] = [{
          "name": entity.providesBoundaries[i].name, 
          "longName": entity.providesBoundaries[i].longName,
          "type": "entitySpace", 
          "versionId": entity.project.versionId,
          "globalId": entity.providesBoundaries[i].globalId
        }];
      }
    }
  }

  return providesBoundaries;
}

function getPropertySetList(entity) {
  let propertySets = {};

  for (let i=0; i<entity.isDefinedBy.length; i++) {
    const propertySetName = entity.isDefinedBy[i].name;
    const properties = entity.isDefinedBy[i].properties;

    if (Object.keys(propertySets).includes(propertySetName)) {
        propertySets[propertySetName].push(properties); // Properties is already a list, need to push each property individually
    } else {
        propertySets[propertySetName] = properties;
    }
  }
  return propertySets;
}

function getMaterialAssociations(entity) {
  if (entity.hasAssociations[0].materialLayers) {
    const materialAssociations = {"materialLayers": entity.hasAssociations[0].materialLayers};
    return materialAssociations;
  } else {
    // throw "Error getting materials";
  }
}

function getContainedInStructure(entity) {
  let containedInStructure = {};
  
  if (Object.keys(entity).includes("containedInStructure")) {
    var entityType = entity.containedInStructure.type;
    if (entity.containedInStructure.type === "IfcBuildingStorey" || entity.containedInStructure.type === "BuildingStorey") {
      entityType = "BuildingStory";
    }
    containedInStructure[entityType] = [{
      "name": entity.containedInStructure.name, 
      "type": entityType, 
      "versionId": entity.project.versionId,
      "globalId": entity.containedInStructure.globalId
    }];
  }
  return containedInStructure;
}

function getConnectedEntitiesList(entity) {
  let connectedEntities = {};

  if (Object.keys(entity).includes("connectedTo")) {
    for (let i=0; i<entity.connectedTo.length; i++) {
      const entityType = entity.connectedTo[i].type;
      // const entityName = entity.connectedTo[i].name; // Change this to name once the names have been added
      console.log(entityType)
      // console.log(`${Object.keys(connectedFrom).includes(entityType)} | ${entityType} in ${Object.keys(connectedFrom)}`);
      if (Object.keys(connectedEntities).includes(entityType)) {
        connectedEntities[entityType].push({
          "name": entity.connectedTo[i].name, 
          "type": "entityBoundary", 
          "versionId": entity.project.versionId,
          "globalId": entity.connectedTo[i].globalId
        });
      } else {
        connectedEntities[entityType] = [{
          "name": entity.connectedTo[i].name, 
          "type": "entityBoundary", 
          "versionId": entity.project.versionId,
          "globalId": entity.connectedTo[i].globalId
        }];
      }
    }
  }

  if (Object.keys(entity).includes("connectedFrom")){
    for (let i=0; i<entity.connectedFrom.length; i++) {
      const entityType = entity.connectedFrom[i].type;
      // const entityName = entity.connectedFrom[i].name; // Change this to name once the names have been added
      // console.log(entityType)
      // console.log(`${Object.keys(connectedFrom).includes(entityType)} | ${entityType} in ${Object.keys(connectedFrom)}`);
      if (Object.keys(connectedEntities).includes(entityType)) {
        connectedEntities[entityType].push({
          "name": entity.connectedFrom[i].name, 
          "type": "entityBoundary", 
          "versionId": entity.project.versionId,
          "globalId": entity.connectedFrom[i].versionId
        });
      } else {
        connectedEntities[entityType] = [{
          "name": entity.connectedFrom[i].name, 
          "type": "entityBoundary", 
          "versionId": entity.project.versionId,
          "globalId": entity.connectedFrom[i].globalId
        }];
      }
    }
  }
  return connectedEntities;
}