import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/hooks/ScrollToTop.js";
import SearchBar from "./components/SearchBar";
import AppDisplay from './components/AppDisplay';
import Footer from './components/Footer';

export default function PrecedentApp() {
    return (
      <Router>
        <ScrollToTop>
          <div>
            <SearchBar />
            <AppDisplay />
            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    );
}
