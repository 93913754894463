import React, { useEffect } from "react";
import { useRealmApp } from "../../RealmApp";
import Card from "react-bootstrap/Card";
import Loading from "../Loading";
import Histogram from "../graphs/Histogram";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Dropdown } from 'react-bootstrap';

export default function StatsCardNumericAnnotation({annotationName='SpaceArea', groupId='', buckets=10}) {
  const app = useRealmApp();
  const [responseData, setResponseData] = React.useState("");
  const [units, setUnits] = React.useState({'label': 'Feet', 'value': 'SQUARE FOOT', 'abbr': 'sq. ft'});

  React.useEffect(() => {
    // app.currentUser.functions.getStatsNumericAnnotation(
    //   annotationName,
    //   groupId,
    //   buckets,
    //   units.value
    // )
    app.currentUser.functions.getAnnotationGroupNumericStats(
      'entitySpace',
      'NameTag',
      groupId,
      units.value
    )
    .then((response) => {
      setResponseData(response);
    })
  }, [annotationName, groupId, buckets, units]);

  if (responseData) {
    // Format response data for histogram buckets
    if (responseData[0].buckets.length === 0 || responseData[0].statistics.length === 0) {
      // If the function doesn't return any data, then return null and don't render anything
      return null;
    } else {
      const title = responseData[0].statistics[0]._id.replace(/([a-z])([A-Z])/g, '$1 $2').trim(); // Put a space between lowercase and uppercase letters
      return (
        <Card className="mt-1 pb-3 border-bottom-0 border-right-0 border-left-0 w-100">
          <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
            {title}
            <DropdownButton
              className="border-0 float-right"
              size="sm"
              variant="light"
              align="end"
              title={units.label}
            >
              <Dropdown.Item
                varient="light"
                onClick={(e) => 
                  setUnits({'label': 'Feet', 'value': 'SQUARE FOOT', 'abbr': 'sq. ft'})
                }
              >Feet</Dropdown.Item>
              <Dropdown.Item
                varient="light"
                onClick={(e) => 
                  setUnits({'label': 'Meters', 'value': 'SQUARE_METRE', 'abbr': 'sq. m'})
                }
              >Meters</Dropdown.Item>
            </DropdownButton>
          </Card.Header>
            <Histogram
              bucketData={responseData[0].buckets}
              xAxisLabel={`Area (${units.abbr})`}
              yAxisLabel="Spaces"
            />
            <SummaryStatistics stats={responseData[0].statistics} units={units} />
        </Card>

      );
    }
  } else {
    return (
      <Loading />
    );
  }
}

function SummaryStatistics({stats, units}) {
  // Round statistics to 1 decimal point
  // TODO: Need to include units
  const roundedSum = Math.round(stats[0].sum*10)/10
  const roundedMin = Math.round(stats[0].min*10)/10
  const roundedMax = Math.round(stats[0].max*10)/10
  const roundedAvg = Math.round(stats[0].average*10)/10
  return (
    <>
      Number of Spaces: {stats[0].count}<br />
      Total Area: {roundedSum} {units.abbr}<br />
      Range: {roundedMin} - {roundedMax} {units.abbr}<br />
      Average: {roundedAvg} {units.abbr}<br />
    </>
  );
}

