import React from "react";
import { Link, useParams } from "react-router-dom";
import { useRealmApp } from "../../RealmApp";
import Loading from "../Loading";
import SearchResultCard from "../cards/SearchResultCard";
import ErrorBoundary from "../ErrorBoundary";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";

export default function SearchScreen({
  props
}) {
  const app = useRealmApp();
  const { searchString } = useParams();
  const [skip, setSkip] = React.useState(0);

  return (
    <div>
      <ErrorBoundary>
        <SearchResults 
          app={app}
          searchString={searchString} 
          skip={skip}
          setSkip={setSkip}/>
      </ErrorBoundary>
    </div>
  );
}

function SearchResults({app, searchString, skip, setSkip}) {
  
  const [responseData, setResponseData] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    app.currentUser.functions.getSearchResults(
      searchString,
      skip
    )
    .then((response) => {
      setIsLoading(false);
      // console.log("API Call - Search");
      setResponseData(response);
    })
  }, [searchString, skip]);

  if (isLoading) {
    return (
        <Loading />
    );
  }
  
  // console.log(`Response Data: ${JSON.stringify(responseData)}`);
  if (responseData) {
    if (responseData.length > 0) {
      return (
        <div>
          {responseData.map((entity, i) => (
            <SearchResultCard 
              key={i} 
              parentEntityName={entity.parentName}
              parentEntityLongName={entity.parentLongName}
              entityId={entity.objectId}
              entityCollection={entity.collection}
              entityName={entity.entityName} 
              entityLongName={entity.entityLongName} />
          ))}
          <PageNavButtons 
            responseLength={responseData.length}
            skip={skip}
            setSkip={setSkip}
          />
        </div>
      );
    } else { // If the search doesn't return any data
      return (
        <SearchResultCard
          entityLongName={"Oops... We couldn't find anything that matches your search"}
        />
      );
    }
  } else {
    return (
      <Loading />
    );
  }
}

function PageNavButtons({responseLength, skip, setSkip}) {
  const prev = skip > 0 ? true : false;
  const next = responseLength >= 10 ? true : false;

  if (prev && next) {
    return (
      <div className="w-75 mx-auto my-sm-5">
        <Button variant="light" onClick={() => setSkip(skip-10)}>Previous</Button>
        <Button variant="light" onClick={() => setSkip(skip+10)} className="ml-sm-2">Next</Button>
      </div>
    )
  }

  if (prev) {
    return (
      <div className="w-75 mx-auto my-sm-4">
        <Button variant="light" onClick={() => setSkip(skip-10)}>Previous</Button>
      </div>
    )
  }

  if (next) {
    return (
      <div className="w-75 mx-auto my-sm-4">
        <Button variant="light" onClick={() => setSkip(skip+10)}>Next</Button>
      </div>
    )
  }

  return <div></div>
}

function nextPage({skip, setSkip}) {
  setSkip(skip+10);
}


