import React from "react";
import chroma from "chroma-js";

export default function Legend({visPropertyValues}) {
  if (visPropertyValues) {
    if (typeof visPropertyValues[0] === 'string') {
      const legendItems = visPropertyValues.map((value, i) => (
        {"color": chroma.hsv(i/visPropertyValues.length*360, .71, .75), "label": value}
      ));

      // Sort legend items alphabetically by label
      legendItems.sort((a, b) => (a.label > b.label) ? 1 : -1)

      return (
        <div>
          <div className="font-weight-bold mb-1">Legend</div>
          {legendItems.map((legendItem) => (
            <LegendItem color={legendItem.color} label={legendItem.label} />
          ))}
        </div>
      );
    }

    if (typeof visPropertyValues[0] === 'number') {
      return <Gradient valueList={visPropertyValues} />
    }

    if (typeof visPropertyValues[0] === 'boolean') {
      const legendItems = [
        {'label': 'True', 'color': chroma.rgb(255, 0, 0)},
        {'label': 'False', 'color': chroma.scale()(.3)},
      ]
      return (
        <div>
          <div className="font-weight-bold mb-1">Legend</div>
          {legendItems.map((legendItem) => (
            <LegendItem color={legendItem.color} label={legendItem.label} />
          ))}
        </div>
      );
    }
  }
  
  return null;
}

function Gradient({valueList}) {
  // See https://cssgradient.io for gradient implementation (including max compatabiltiy CSS properties)
  const colorScale = chroma.scale('Blues').padding([.45, 0]) // This is the same gradient used in the renderer TODO: make this scale dynamic
  const minColor = colorScale(0);
  const maxColor = colorScale(1);
  const minValue = Math.min(...valueList).toFixed(3); // Math.round(Math.min(...valueList));
  const maxValue = Math.max(...valueList).toFixed(3); // Math.round(Math.max(...valueList));
  const myStyle = {
    height: "10px",
    "margin-bottom": "2px",
    background: `linear-gradient(to right, ${minColor.hex()}, ${maxColor.hex()})`
  }

  return (
    <>
      <span className="font-weight-bold mb-1">Legend</span>
      <div style={myStyle}></div>
      <div>
        <small className="float-left">{minValue}</small>
        <small className="float-right">{maxValue}</small>
      </div>
    </>
  );
}

function LegendItem({color, label}) {
  const myStyle = {
    "padding-right": "10px",
    "float": "left"
  }
  return (
    <div style={myStyle}>
      <LegendItemColor color={color} /><LegendItemLabel label={label} />
    </div>
  )
}

function LegendItemColor({color}) {
  const myStyle = {
    height: "10px",
    width: "10px",
    float: "left",
    "margin-top": "7px",
    "margin-right": "4px",
    background: color.hex()
  }
  return <div style={myStyle}></div>
}

function LegendItemLabel({label}) {
  return <div style={{float: "left"}}>{label}</div>
}