import React from 'react';
import Card from 'react-bootstrap/Card';
import GeoJsonRender from '../viewer/GeoJsonRender';

export default function GeoJsonViewerCard({geoJson=null}) {
  
  if (geoJson) {
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
      <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
        Model
      </Card.Header>
        <GeoJsonRender
          geoJson={geoJson}
        />
      </Card>
    );
  } else {
    return (
      null
    );
  }
}