import React from "react";
import { useRealmApp } from "../../RealmApp";
import Card from "react-bootstrap/Card";
import CommentDisplay from "../cards/CommentDisplay";
import CommentForm from "../cards/CommentForm";
import Loading from "../Loading";

export default function CommentsCard({objectId, collectionName}) {
  const app = useRealmApp();
  const [commentLimit, setCommentLimit] = React.useState(5); // Controls how many comments are returned from the app

  const [comments, setComments] = React.useState("");

  React.useEffect(() => {
    app.currentUser.functions.getComments(
      objectId,
      commentLimit
    )
    .then((response) => {
      setComments(response);
    })
  }, [objectId, commentLimit]);

  if (comments) {
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
        <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">Comments</Card.Header>
        <div className="m-0">
          <CommentDisplay 
            app={app} 
            objectId={objectId} 
            comments={comments}
            setComments={setComments}
            commentLimit={commentLimit}
            setCommentLimit={setCommentLimit}
          />
          <CommentForm 
            app={app} 
            objectId={objectId} 
            collectionName={collectionName} 
            comments={comments}
            setComments={setComments}
          />
        </div>
      </Card>
    );
      // <>
        
      //   <CommentDisplay
      //     app={app}
      //     comments={responseData} 
      //     sortOrder={sortOrder}
      //   />
      // </>
  } else {
    return (
      <Loading />
    );
  }

  // if (objectId) {
  //   return (
  //     <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
  //       <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">Comments</Card.Header>
  //       <div className="m-0">
          
  //         <CommentDisplay 
  //           app={app} 
  //           objectId={objectId} 
  //           sortOrder={1}
  //         />
  //         <CommentForm 
  //           app={app} 
  //           objectId={objectId} 
  //           collectionName={collectionName} 
  //         />
  //       </div>
  //     </Card>
  //   );
  // }

  // NOTE: I don't think this is used anymore, the function to show hte latest comments has been replaced by the ConversationDisplay
  // if an objectId is not specified, don't snow the "load older comments link or the form to add comments"
  // if (!objectId) {
  //   return (
  //     <Card className="mt-4 border-bottom-0 border-right-0 border-left-0 w-100">
  //       <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">Comments</Card.Header>
  //       <div className="m-0">
  //         <CommentDisplay 
  //           app={app} 
  //           objectId={objectId} 
  //           sortOrder={1}
  //         />
  //       </div>
        
  //     </Card>
  //   );
  // }
}

