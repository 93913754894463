import React from "react";
import { useParams } from "react-router-dom";
import { useRealmApp } from "../../RealmApp";
import Loading from "../Loading";
import ErrorBoundary from "../ErrorBoundary";
import EntityHeaderCard from "../cards/EntityHeaderCard";
import RelatedEntitiesCard from "../cards/RelatedEntitiesCard";
import CommentsCard from "../cards/CommentsCard";
import RelatedListCard from "../cards/RelatedListCard";
import ObjectAnnotations from "../cards/ObjectAnnotations";
import GeoJsonSpaceViewerCard from "../cards/GeoJsonSpaceViewerCard";

export default function SpaceScreen() {
  const app = useRealmApp();
  const params = useParams();

  var props = null;
  if (params.p) {
    props = {
      "objectId": "",
      "versionId": params.p,
      "globalId": params.id
    }
  } else {
    props = {
      "objectId": params.id,
      "versionId": "",
      "globalId": ""
    }
  }

  return (
    <div>
      <ErrorBoundary>
        <ApiResults 
          app={app}
          props={props}
        />
      </ErrorBoundary>
    </div>
  );
}

function ApiResults({app, props}) {

  const [responseData, setResponseData] = React.useState("");

  React.useEffect(() => {
    app.currentUser.functions.getDocument(
      'entitySpace',
      props.objectId,
      props.versionId,
      props.globalId
    )
    .then((response) => {
      console.log("API Call - Space");
      setResponseData(response);
      console.log("Response: ", response);
    })
  }, [props, app.currentUser.functions]);

  if (responseData) {
    return (
      <SpaceCardLayout 
        entity={responseData.results} 
      />
    );
  } else {
    return (
      <Loading />
    );
  }
}

function SpaceCardLayout ({entity}) {
  // get the MongoDB objectId string - used to pull the comments for the entity
  const objectId = entity._id.toString();
  var entityProperties = {}

  let containsElementTypes = []; // Array used to filter the dropdown in the GeoJson viewer

  if (Object.keys(entity).includes("isDefinedBy")) {
    entityProperties["propertySets"] = getPropertySetList(entity);
  }
  // if (Object.keys(entity).includes("decomposes")) {
  //   entityProperties["relatedEntities"] = getRelatedEntitiesList(entity);
  // }
  if (Object.keys(entity).includes("boundedBy")) {
    entityProperties["boundedBy"] = getBoundaryObjectsList(entity);
    containsElementTypes.push(...Object.keys(entityProperties["boundedBy"]));
    if (Object.keys(entityProperties['boundedBy']).includes("WallStandardCase")) {
      // WallStandardCase is not used to get query furniture from the database
      // Need to add Wall to the list in order to trigger it
      containsElementTypes.push("Wall");
    }
  }
  if (Object.keys(entity).includes("containsElements")) {
    entityProperties["containsElements"] = getContainsElementsList(entity);
    containsElementTypes.push(...Object.keys(entityProperties["containsElements"]));
    if (Object.keys(entityProperties['containsElements']).includes("FurnishingElement")) {
      // FurnishingElement is not used to get query furniture from the database
      // Need to add Furniture to the list in order to trigger it
      containsElementTypes.push("Furniture");
    }
  }

  // const entityProperties = {relatedEntities, boundaryObjects, containsElements};

  if (entity.geoJson) { // if there is a representation then render the Viewer card
    return (
      <div className="w-75 mx-auto">
        <div className="row">
          <EntityHeaderCard 
            parentEntityName={entity.project.name}
            parentEntityLongName={entity.project.longName}
            entityName={entity.name} 
            entityLongName={entity.longName}
          />
        </div>

        <div className="row">
          <div className="col-9">
            <div className="row">
              <div className="col-12">
                <GeoJsonSpaceViewerCard 
                  objectId={objectId} 
                  containsElementTypes={containsElementTypes}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-3">
                <RelatedListCard versionId={entity.project.versionId} relatedLists={[entity.decomposes]} />
              </div>
              <div className="col-9">
                <RelatedEntitiesCard
                  entityProperties={entityProperties}
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <ObjectAnnotations objectId={objectId} />
            <CommentsCard objectId={objectId} collectionName="entitySpace" />
          </div>
        </div>
      </div>
    );
  } else { // if there isn't a representation, then DO NOT render the Three Viewer Card
    return (
      <div className="w-75 mx-auto">
        <EntityHeaderCard 
          parentEntityName={entity.project.name}
          parentEntityLongName={entity.project.longName}
          entityName={entity.name} 
          entityLongName={entity.longName}
        />
        <div className="row">
          <div className="col-3 px-1">
            <RelatedListCard versionId={entity.project.versionId} relatedLists={[entity.decomposes]} />
          </div>
          <div className="col-6 px-1">
            <RelatedEntitiesCard
              entityProperties={entityProperties}
            />
          </div>
          <div className="col-3">
            <ObjectAnnotations objectId={objectId} />
            <CommentsCard objectId={objectId} collectionName="entitySpace" />
          </div>
        </div>
      </div>
    );
  }
}

function getPropertySetList(entity) {
  let propertySets = {};

  for (let i=0; i<entity.isDefinedBy.length; i++) {
    const propertySetName = entity.isDefinedBy[i].name;
    const properties = entity.isDefinedBy[i].properties;

    if (Object.keys(propertySets).includes(propertySetName)) {
        propertySets[propertySetName].push(properties); // Properties is already a list, need to push each property individually
    } else {
        propertySets[propertySetName] = properties;
    }
  }
  return propertySets;
}

function getBoundaryObjectsList(entity) {
  // sort boundaryObjects list into object with the object type as keys
  let boundaryObjects = {};
  
  try {
    for (var i=0; i<entity.boundedBy.length; i++) {
      const boundaryObjectType = entity.boundedBy[i].type;
      // const boundaryObjectName = entity.boundedBy[i].name;
      // const boundaryObjectID = entity.boundedBy[i].globalId;
      // console.log(boundaryObjectID);
      // console.log(boundaryObjectType)
      // console.log(`${Object.keys(boundaryObjects).includes(boundaryObjectType)} | ${boundaryObjectType} in ${Object.keys(boundaryObjects)}`);
      if (Object.keys(boundaryObjects).includes(boundaryObjectType)) {
        boundaryObjects[boundaryObjectType].push({
          "name": entity.boundedBy[i].name, 
          "type": "entityBoundary", 
          "versionId": entity.project.versionId,
          "globalId": entity.boundedBy[i].globalId});
          // console.log("`Append: ${boundaryObjectName}`");
          // boundaryObjects[boundaryObjectType] = [this.boundaryObjects[boundaryObjectType], boundaryObjectName];
      } else {
        boundaryObjects[boundaryObjectType] = [{
          "name": entity.boundedBy[i].name, 
          "type": "entityBoundary", 
          "versionId": entity.project.versionId,
          "globalId": entity.boundedBy[i].globalId
        }];
        // boundaryObjects[boundaryObjectType].concat([boundaryObjectName]);
      }
    }
  } catch (e) {
    console.log("Boundary objects not found");
    console.log(`Error: ${e}`);
  }
  // console.log(boundaryObjects);
  return boundaryObjects;
}

function getContainsElementsList(entity) {
  let containsElements = {};
  // try {
    for (var i=0; i<entity.containsElements.length; i++) {
      const containsElementType = entity.containsElements[i].type;
      // const containsElementName = entity.containsElements[i].name; // Change this to name once the names have been added
      let containsElementCollection = null;
      if (containsElementType === "BuildingElementProxy" || containsElementType === "Furniture" || containsElementType === "FurnishingElement") {
        containsElementCollection = "entityInterior";
      } else {
        containsElementCollection = "entityBoundary";
      }
      // console.log(containsElementType)
      // console.log(`${Object.keys(containsElements).includes(containsElementType)} | ${containsElementType} in ${Object.keys(containsElements)}`);
      if (Object.keys(containsElements).includes(containsElementType)) {
        containsElements[containsElementType].push({
          "name": entity.containsElements[i].name, 
          "type": containsElementCollection, 
          "versionId": entity.project.versionId,
          "globalId": entity.containsElements[i].globalId
        });
      } else {
        containsElements[containsElementType] = [{
          "name": entity.containsElements[i].name, 
          "type": containsElementCollection, 
          "versionId": entity.project.versionId,
          "globalId": entity.containsElements[i].globalId
        }];
      }
    }
  // } catch {
  //   console.log("Interior elements not found");
  // }
  // console.log(containsElements);
  return containsElements;
}

// function getRelatedEntitiesList(entity) {
//   let relatedEntities = {};
//   if (Object.keys(entity).includes("decomposes")) {
//     relatedEntities["BuildingStory"] = [{
//       "name": entity.decomposes[0].name, 
//       "type": "entityBuildingStory", 
//       "versionId": entity.project.versionId,
//       "globalId": entity.decomposes[0].globalId
//     }];
//   }
//   return relatedEntities;
// }