import React from "react";
import { useRealmApp } from "../../RealmApp";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Form from "react-bootstrap/Form";
import Map from "../maps/Map";
import styled from "@emotion/styled";

export default function MapCard({objectId}) {
  const app = useRealmApp();
  
  const [displayState, setDisplayState] = React.useState("show"); // display state options: "show" or "edit"
  const [mapStyle, setMapStyle] = React.useState("default");
  const [newAddress, setNewAddress] = React.useState(""); // used when a new address is entered
  const [userMessage, setUserMessage] = React.useState("");

  if (displayState === "show") {
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
      <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
        Location
        <Button
          variant="outline-secondary"
          // if an objectId is specified show the edit button, Without an objectId, the edit location annotation function won't know which document to update
          className={objectId ? "float-right border-0 visible" : "float-right border-0 invisible"}
          size="sm"
          onClick={(e) => {
            // e.preventDefault();
            setDisplayState("edit");
          }
        }>Edit</Button>
        <ToggleButtonExample 
          mapStyle={mapStyle}
          setMapStyle={setMapStyle}
        />
      </Card.Header>
      <Map objectId={objectId} mapStyle={mapStyle} />
      </Card>
    );
  }

  if (displayState === "edit") {
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
      <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
          Map
          <Button
            variant="outline-secondary"
            className="float-right border-0" 
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              setDisplayState("show");
          }}>Edit</Button>
        </Card.Header>
      <div className="my-3">
        <Form>
          <Form.Group>
            <Form.Label>Enter New Address</Form.Label>
            <Form.Control 
              onChange={(e) => {setNewAddress(e.target.value)}}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  changeAddress(app, e, objectId, newAddress, displayState, setDisplayState);
                }
              }}
            />
          </Form.Group>
          <Button
            onClick={(e) => {
              changeAddress(app, e, objectId, newAddress, displayState, setDisplayState, userMessage, setUserMessage);
              // e.preventDefault();
              // app.currentUser.functions.updateLocation(objectId, newAddress);
              // setDisplayState("show");
            }}
          >Submit</Button>
          <UserMessage>{userMessage}</UserMessage>
        </Form>
      </div>
      </Card>
    );
  }
}

async function changeAddress(
  app, e, objectId, newAddress, displayState, setDisplayState, userMessage, setUserMessage
) {
  try {
    e.preventDefault();
    await app.currentUser.functions.updateLocation(objectId, newAddress);
    // TODO: Write code to show user that there was an error if a new location isn't found
    // setDisplayState("show");
    setUserMessage("Successfully updated the location");
  } catch (err) {
    setUserMessage("We couldn't find this address, please try again");
  }
}

function ToggleButtonExample({mapStyle, setMapStyle}) {
  // const [checked, setChecked] = useState(false);

  const radios = [
    { name: 'Default', value: 'default' },
    { name: 'Satellite', value: 'satellite' },
    { name: 'Topographic', value: 'topography' },
  ];

  return (
    <ButtonGroup className="float-right" toggle>
      {radios.map((radio, idx) => (
        <ToggleButton
          className="border-0 text-muted"
          key={idx}
          type="radio"
          variant="outline-light"
          name="radio"
          size="sm"
          value={radio.value}
          checked={mapStyle === radio.value}
          onChange={(e) => setMapStyle(e.currentTarget.value)}
        >
          {radio.name}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
}

const UserMessage = styled.span`
  padding-left: 1.5em;
  margin-top: 15px;
  font-style: italic;
`;