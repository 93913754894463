import * as React from 'react';
import { useState } from 'react';
import { useRealmApp } from "../../RealmApp";
import ReactMapGL from 'react-map-gl';
import MapPins from '../maps/MapPins';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// The above is a fix for the blank map created by Mapbox's new ES6 module/tranpilation issues
// Fix is from here: https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-799627909

export default function Map({objectId, mapStyle='default', width=null}) {
  const app = useRealmApp();

  // Map Configuration Options  
  const mapStyleUrls = {
    'default': 'mapbox://styles/trevorhess/cknumqpfn0ztu17qr577rwsfv',
    'satellite': 'mapbox://styles/trevorhess/ckobsa3an1eta17p115fshn8g',
    'topography': 'mapbox://styles/trevorhess/ckobs3h7k0te119o4uc6k3mtx'
  }

  // const mapStyle = "mapbox://styles/trevorhess/cknpdxvwg5mys17t8fz0iizm7"; // Gray map style
  // const mapStyle = "mapbox://styles/trevorhess/cknumqpfn0ztu17qr577rwsfv"; // Basic map style

  const contentRef = React.useRef(null);
  const [viewport, setViewport] = useState({
    width: 550,
    height: 300,
    latitude: 37.0902,
    longitude: -95.7129,
    zoom: 3
  });
  const [settings, setSettings] = useState({
    dragPan: true,
    dragRotate: false,
    scrollZoom: true,
    touchZoom: true,
    touchRotate: true,
    keyboard: true,
    doubleClickZoom: true,
    minZoom: 0,
    maxZoom: 20,
    minPitch: 0,
    maxPitch: 0
  });

  // Response from Realm app - data contains locations pulled from location annotations
  const [responseData, setResponseData] = React.useState("");

  React.useEffect(() => {
    app.currentUser.functions.getGeoJson(objectId)
    .then((response) => {
      setResponseData(response);
      // If there is only one location in the response data, update the viewport settings to center and zoom in on that point
      if (response[0]) {
        if (response[0].features.length === 1) {
          // if there is only one location returned in the query, then set up the viewport to zoom in on that point.
          const longitude = parseFloat(response[0].features[0].geometry.coordinates[0]);
          const latitude = parseFloat(response[0].features[0].geometry.coordinates[1]);

          setViewport({
            ...viewport,
            'latitude': latitude,
            'longitude': longitude,
            'zoom': 14
          });
        }
      }
    })
  }, [objectId]);

  if (responseData && responseData.length >= 1) {

    return (
      <div className="relative w-full h-full" ref={contentRef}>
        <ReactMapGL
          {...viewport}
          width='100%'
          height='400px'
          mapStyle={mapStyleUrls[mapStyle]}
          onViewportChange={nextViewport => setViewport(nextViewport)}
        >
          <MapPins data={responseData} onClick={""} />
        </ReactMapGL>
      </div>
    );
  } else {
    return (
      <div className="relative w-full h-full" ref={contentRef}>
        <ReactMapGL
          {...viewport}
          width='100%'
          height='400px'
          mapStyle={mapStyleUrls[mapStyle]}
          onViewportChange={nextViewport => setViewport(nextViewport)}
        >
        </ReactMapGL>
      </div>
    );
  }
}