import React from 'react';
import Card from 'react-bootstrap/Card';
import { useRealmApp } from "../../RealmApp";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Dropdown } from 'react-bootstrap';
import SpaceRender from '../viewer/SpaceRender';
import Loading from '../Loading';

export default function GeoJsonViewerCard({objectId, containsElementTypes=[]}) {
  const app = useRealmApp();

  const [modelSettings, setModelSettings] = React.useState({'objectId': objectId});
  const [responseData, setResponseData] = React.useState("");

  React.useEffect(() => {
    setResponseData("");
    app.currentUser.functions.getFeatureCollectionSpace(objectId, modelSettings)
    .then((response) => {
      console.log(response);
      setResponseData(response);
    })
  }, [objectId, modelSettings, app.currentUser.functions]);

  if (responseData) {
    console.log(responseData);
    
    if (!responseData[0]) {
      return (
        <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
          <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
            Model
            <ToggleButtonExample 
              containsElementTypes={containsElementTypes}
              modelSettings={modelSettings}
              setModelSettings={setModelSettings}
            />
          </Card.Header>
          <Card.Body>
            Oops... We couldn't find any {modelSettings.type.toLowerCase()}s in the model.
          </Card.Body>
        </Card>
      );
    }
    
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
      <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
        Model
        <ToggleButtonExample 
          containsElementTypes={containsElementTypes}
          modelSettings={modelSettings}
          setModelSettings={setModelSettings}
        />
      </Card.Header>
        <SpaceRender
          geoJson={responseData[0]}
          modelSettings={modelSettings}
        />
      </Card>
    );
  } else {
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
      <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
        Model
        <ToggleButtonExample 
          containsElementTypes={containsElementTypes}
          modelSettings={modelSettings}
          setModelSettings={setModelSettings}
        />
      </Card.Header>
      <Loading />
      </Card>
    );
  }
}

function ToggleButtonExample({containsElementTypes, modelSettings, setModelSettings}) {

  const displayOptions = [
    {
      "header": "Boundary Objects",
      "items": [
        {"label": "Ceilings", "settings": {"type": "Covering", "related": false}},
        {"label": "Doors", "settings": {"type": "Door", "related": false}},
        {"label": "Slabs", "settings": {"type": "Slab", "related": false}},
        {"label": "Walls", "settings": {"type": "Wall"}, "related": false},
        {"label": "Windows", "settings": {"type": "Window", "related": false}}
    ]},
    {"header": "Object Locations",
    "items": [
      {"label": "Columns", "settings": {"type": "Column", "related": false}},
      {"label": "Light Fixtures", "settings": {"type": "LightFixture", "related": false}},
      {"label": "Furniture", "settings": {"type": "Furniture", "related": false}},
      {"label": "Other Objects", "settings": {"type": "BuildingElementProxy", "related": false}}
    ]},
    {"header": "Related Spaces",
    "items": [
      {"label": "Shared Doors", "settings": {"type": "Door", "related": true}},
      {"label": "Shared Walls", "settings": {"type": "Wall", "related": true}}
    ]}
  ];

  return (
    <ButtonGroup className="float-right">
      <DropdownButton
        className="border-0"
        size="sm"
        variant="light"
        title="Show"
      >
        {displayOptions.map((group) => (
          <>
            <Dropdown.Header className="text-muted">{group.header}</Dropdown.Header>
            <DisplayItems 
              displayItems={group.items} 
              containsElementTypes={containsElementTypes}
              modelSettings={modelSettings} 
              setModelSettings={setModelSettings} 
            />
          </>
        ))}
      </DropdownButton>
    </ButtonGroup>
  );
}

function DisplayItems({displayItems, containsElementTypes, modelSettings, setModelSettings}) {
  return (
    displayItems.map((item) => {
      if (containsElementTypes.includes(item.settings.type) || containsElementTypes.length === 0) {
        return (
          <Dropdown.Item
            varient="light"
            onClick={(e) => 
              setModelSettings({...modelSettings, "type": item.settings.type, "related": item.settings.related})
            }
          >{item.label}</Dropdown.Item>
        );
      }
      return null;
    })
  );
}