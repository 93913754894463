import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useRealmApp } from "../../RealmApp";
import Loading from "../Loading";
import ErrorBoundary from "../ErrorBoundary";
import Card from "react-bootstrap/Card";
import ExperienceCard from "../cards/ExperienceCard";
import ConversationDisplay from "../cards/ConversationDisplay";
import InsightsCard from "../cards/InsightsCard";
import DonutChartCard from "../cards/DonutChartCard";
import SceneTest from "../viewer/SceneTest";

export default function HomeScreen() {
  const app = useRealmApp();

  return (
    <div>
      <ErrorBoundary>
        <HomeCardLayout
          app={app}
        />
      </ErrorBoundary>
    </div>
  );
}

function HomeCardLayout ({app}) {
    return (
      <div className="w-75 mx-auto mb-4">
        {/* <TextCard data={data[0]} /> */}
        <div className="row">
          <div className="col-9 m-0 py-2">
            <ExperienceCard />
            <div className="row">
              <div className="col-3 m-0 py-2 px-0">
                <DonutChartCard annotationName='PracticeArea' />
                <DonutChartCard annotationName='Client' />
              </div>
              <div className="col-9 m-0 py-2">
                <InsightsCard />
              </div>
            </div>
          </div>
          <div className="col-3 m-0 p-2">
            <Card className="mt-1 py-2 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
              <h3>Conversations</h3>
              <ConversationDisplay app={app} limit={15} />
            </Card>
          </div>
        </div>
      </div>
    );
}

function TextCard({data}) {
  return (
    <div className="row p-3 mt-4">
      <h2>{data.title}</h2>
      {
        data.groups.map((text, i) => (
          <p key={i}>{text}</p>
        ))
      }
    </div>
  );
}

function LinkListCard({data, options=null}) {
  return (
    <div className="col-3 h-100">
      <h4>{data.name}</h4>
      <ul>
      {
        data.groups.map((item, i) => (
          <li key={i}>
            <Link to={`${options.linkPrefix}${item[options.linkKey]}`}>
              {item.value}
            </Link>
          </li>
        ))
      }
      </ul>
    </div>
  );
}

function ListCard({data, options=null}) {
  return (
    <div className="col-9 px-3">
      <div className="row"><h4>{data.name}</h4></div>
      <CardDeckGridMed>
      {
        data.groups.map((group, i) => (
          <div key={i}>
            <h6>{group.label}</h6>
            <List items={group.items} />
          </div>
        ))
      }
      </CardDeckGridMed>
    </div>
  );
}

function List({items, options=null}) {
  return (
    <ul>
      {
        items.map((item, i) => (
          <li key={i}>{item}</li>
        ))
      }
    </ul>
  );
}

function StatsRow({data}) {
  return (
  <div className="row mt-4">
      <div className="col-3 border-0 p-3 bg-light">
        <h2>{data.title}</h2>
        <p className="text-muted">
          {data.description}
        </p>
      </div>
      <div className="col-9 border-left-0 border-right-0">
        <CardDeckGridSmall>
          {data.groups.map((item, i) => (
            <StatCard 
              key={i}
              label={item.label}
              value={item.value}
            />
          ))}
        </CardDeckGridSmall>
      </div>
    </div>
  );
}

function StatsTable({
  label, 
  properties,
  description}) {
  return (
    <div className="row mt-4">
      <div className="col-3 border-0 p-3 bg-light">
        <h2>{label}</h2>
        <p className="text-muted">
          {description}
        </p>
      </div>
      <div className="col-9 border-left-0 border-right-0">
        <CardDeckGridSmall>
          {properties.map((property, i) => (
            <StatCard 
              key={i}
              label={property.label}
              value={property.value}
            />
          ))}
        </CardDeckGridSmall>
      </div>
    </div>
  );
}

function StatCard({
  label, 
  value
}) {
  // Format the label by adding spaces and capitalizing letters
  let name = "";
  name = label.replace(/([a-z])([A-Z])/g, '$1 $2').trim() // Put a space between lowercase and uppercase letters
  name = name.replace(/(\b[a-z](?!\s))/g, function(x){return x.toUpperCase();}); // Capitalize the first letter after every space
  return (
    <Link to={`/search/:${label}`}>
      <Card className="p-1 border-0 text-center" >
        <small className="text-muted">{name}</small>
        <h3>{value}</h3>
      </Card>
    </Link>
  );
}

const CardDeckGridSmall = styled.div`
  margin-top: 10px;
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  grid-gap: .5rem;
`;

const CardDeckGridMed = styled.div`
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  grid-gap: .5rem;
`;

