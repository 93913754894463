import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function CommentForm({app, objectId, collectionName, comments, setComments}) {

  const currentUser = app.currentUser;
  const [commentText, setCommentText] = React.useState("");
  const [userMessage, setUserMessage] = React.useState("");
  // TODO: Figure out a way to support markdown and user mentions
  return (
    <Form className="mx-1 mt-3">
      <Form.Row>
        <Form.Control 
          className="mb-2" 
          as="textarea"
          rows="3"
          placeholder="Leave a comment..."
          value={commentText} 
          onChange={(e) => setCommentText(e.target.value)}/>
      </Form.Row>
      <Form.Row className="mt-1">
        <Button 
          variant="primary"
          size="sm"
          onClick={(e) => submitComment({e, 
            currentUser, 
            objectId,
            collectionName,
            commentText,
            setCommentText,
            setUserMessage,
            comments,
            setComments
          })}>Post</Button>
      </Form.Row>
      <small className="text-muted">{userMessage}</small>
    </Form>
  );
}

function submitComment({
  currentUser, 
  objectId,
  collectionName,
  commentText, 
  setCommentText,
  setUserMessage,
  comments,
  setComments
}) {
  console.log(currentUser.customData);
  if (commentText !== "") {
    try {
      // Object for the comment that is added to the end of the comments array
      // This object only contains the information needed to display the comment, the document on the database contains more information
      const commentObject = {
        "metadata": {
          "source": {
            // "userId": currentUser.id, 
            // Not providing a userID means that the delete link won't be shown.
            // The delete function for comments added to the array would throw an error because they rely on an ObjectId (which is created during the insert into the collection)
            // TODO: Generate an ObjectID on the client side, and use that ID in the setComment function. This will allow the deleteComment function to work correctly
            "userName": currentUser.customData.data.email
          }
        },
        "comment": {
          "text": commentText
        },
        "domain": currentUser.customData.domain
      }
    
      currentUser.functions.setComment(objectId, collectionName, commentText); // Create the comment in the database

      setCommentText(""); // Clear the comments form
      setComments([...comments, commentObject]) // Add the comment to the comments array after it is created in the database
    
    } catch(e) {
      setUserMessage("Oops... We ran into a problem posting your comment");
      console.log(e);
    }
  }
}