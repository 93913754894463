import React, { useEffect } from "react";
import { useRealmApp } from "../../RealmApp";
import Card from "react-bootstrap/Card";
import Loading from "../Loading";
import Histogram from "../graphs/Histogram";

export default function StatsCardNumericAnnotation({objectType='Window', groupName='', buckets=10}) {
  const app = useRealmApp();
  const [responseData, setResponseData] = React.useState("");

  React.useEffect(() => {
    // app.currentUser.functions.getStatsGroupTypeCount(
    //   groupId,
    //   objectType,
    //   buckets
    // )
    app.currentUser.functions.getAnnotationGroupSpaceObjectsStats(
      'entitySpace',
      'NameTag',
      groupName,
      objectType
    )
    .then((response) => {
      setResponseData(response);
    })
  }, [groupName]);

  if (responseData) {
    // Format response data for histogram buckets
    if (responseData[0].buckets.length == 0 || responseData[0].statistics.length == 0) {
      // Return null if the function doesn't return any data
      return null;
    } else {
      return (
        <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100">
          <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">{responseData[0].statistics[0]._id} Statistics</Card.Header>
          <Histogram
            bucketData={responseData[0].buckets}
            xAxisLabel={`${responseData[0].statistics[0]._id}s per space`}
            yAxisLabel="Spaces"
          />
          <SemanticSummary objectType={objectType} stats = {responseData[0].statistics} />
          {/* <SummaryStatistics stats={responseData[0].statistics} /> */}
        </Card>
      );
    }
  } else {
    return (
      <Loading />
    );
  }
}

function SummaryStatistics({stats}) {
  // Round statistics to 1 decimal point
  // TODO: Need to include units
  const roundedSum = Math.round(stats[0].sum*10)/10
  const roundedMin = Math.round(stats[0].min*10)/10
  const roundedMax = Math.round(stats[0].max*10)/10
  const roundedAvg = Math.round(stats[0].average*10)/10
  return (
    <>
      Sample Size: {stats[0].count}<br />
      Total: {roundedSum}<br />
      Range: {roundedMin} - {roundedMax}<br />
      Average: {roundedAvg}<br />
    </>
  );
}

function SemanticSummary({objectType, stats}) {
  const lowerCaseObjectType = objectType.toLowerCase();
  const count = stats[0].count;
  const roundedSum = Math.round(stats[0].sum*10)/10
  const roundedMin = Math.round(stats[0].min*10)/10
  const roundedMax = Math.round(stats[0].max*10)/10
  const roundedAvg = Math.round(stats[0].average*10)/10

  const summary = `We found ${roundedSum} ${lowerCaseObjectType}s spread across ${count} spaces. The number of ${lowerCaseObjectType}s per space ranged from ${roundedMin} to ${roundedMax}; on average each space had ${roundedAvg} ${lowerCaseObjectType}s.`
  return (
    <p>
      {summary}
    </p>
  )
}