import HubspotForm from 'react-hubspot-form';
import styled from "@emotion/styled";
 
export default function SupportScreen() {

  const Script = document.createElement('script');
  Script.src = "//js.hsforms.net/forms/shell.js";

  const hsForm = {
    region: "na1",
    portalId: "8596068",
    formId: "d1575215-24e8-40cd-93fe-d7b14eacd641"
  };

  return (
    <Container>
      <div className="w-50">
      <h1 className="mb-4">Submit a Support Ticket</h1>
      <HubspotForm
        portalId={hsForm.portalId}
        formId={hsForm.formId}
        onSubmit={() => console.log('Submit!')}
        onReady={(form) => console.log('Form ready!')}
        loading={<div>Loading...</div>}
      />
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 90vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;