import React from "react";
import * as Realm from "realm-web";
import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import { useRealmApp } from "../../RealmApp";
import styled from "@emotion/styled";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import validator from "validator";
import background from "../../assets/img/login_background.jpg"

export default function LoginScreen() {
  const app = useRealmApp();

  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const tokenId = params.get("tokenId");

  // Toggle between logging users in and registering new users
  const [mode, setMode] = React.useState("login");
  
  // Keep track of form input state
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  // Keep track of input validation/errors
  const [error, setError] = React.useState({});
  // Whenever the mode changes, clear the form inputs
  React.useEffect(() => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setError({});
  }, [mode]);

  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  

  return (
    <Container style={{ backgroundImage: `url(${background})` }}>
      <Router>
        <Switch>
          <Route path="/confirmpassword/">
            <ConfirmEmailMessage />
          </Route>
          <Route path="/resetpassword/">
            <ResetPasswordForm 
              app={app}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              error={error}
              setError={setError}
              token={token}
              tokenId={tokenId}
            />
          </Route>
          
          <Route path="*"> {/* No Match -> Default Screen */}
            <LogInRegisterForm 
              app={app}
              mode={mode}
              setMode={setMode}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              error={error}
              setError={setError}
              isLoggingIn={isLoggingIn}
              setIsLoggingIn={setIsLoggingIn}
            />
          </Route>
        </Switch>
      </Router>
    </Container>
  );
}

function ConfirmEmailMessage() {
  return ("");
}

function ResetPasswordForm({app, email, setEmail, password, setPassword, confirmPassword, setConfirmPassword, error, setError, token, tokenId}) {
  
  const handlePasswordResetRequest = async () => {
    const isValidEmailAddress = validator.isEmail(email);
    setError((e) => ({ ...e, password: null }));
    if (isValidEmailAddress) {
      try {
        await app.emailPasswordAuth.sendResetPasswordEmail(email);
      } catch (err) {
        handleAuthenticationError(err, setError);
      }
    } else {
      setError((err) => ({...err, email: "Email is invalid"}));
    }
  }

  const handlePasswordReset = async () => {
    setError((e) => ({ ...e, password: null }));
    if (password === confirmPassword) {
      try {
        console.log("Resetting Password");
        console.log("Token:", token, "TokenId", tokenId);
        await app.emailPasswordAuth.resetPassword(token, tokenId, password);
      } catch (err) {
        handleAuthenticationError(err, setError);
      }
    } else {
      setError((err) => ({...err, password: "Passwords must match"}))
    }
  }

  return (
    <SignInForm>
      {/* <FormTitle>{mode === "login" ? "Log In" : "Register"}</FormTitle> */}
      <FormTitle>Precedent</FormTitle>
      { (token && tokenId) ? 
        "" :  (<FormEmail
          type="email" 
          placeholder="Email" 
          onChange={(e) => {
            setError((e) => ({ ...e, email: null }));
            setEmail(e.target.value.toLowerCase());
          }}
          value={email}
          // errorMessage={error.email}
        />)
      }
      { (token && tokenId) ?
        (<>
          <FormPassword
            type="password" 
            placeholder="New Password" 
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <FormPassword
            type="password" 
            placeholder="Confirm New Password" 
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
        </>) : ""
      }
      
      <ErrorText>{error.email ? error.email: ""} {error.password ? error.password: ""}</ErrorText>
      <SignInButton
        variant="light"
        onClick={() => {
          if (token && tokenId) {
            handlePasswordReset();
          } else {
            handlePasswordResetRequest();
          }
        }}
      >
      { token && tokenId ? "Set New Password" : "Request Password Reset Link" }
      </SignInButton>
      <ToggleContainer>
        <Link to="/">Take me to the login screen</Link>
      </ToggleContainer>
    </SignInForm>
  );
}

function LogInRegisterForm({app, mode, setMode, email, setEmail, password, setPassword, error, setError, isLoggingIn, setIsLoggingIn}) {

  const toggleMode = () => {
    setMode((oldMode) => (oldMode === "login" ? "register" : "login"));
  };

  const handleLogin = async () => {
    setIsLoggingIn(true);
    setError((e) => ({ ...e, password: null }));
    try {
       await app.logIn(Realm.Credentials.emailPassword(email, password));
    } catch (err) {
      handleAuthenticationError(err, setError);
    }
  };

  const handleRegistrationAndLogin = async () => {
    const isValidEmailAddress = validator.isEmail(email);
    setError((e) => ({ ...e, password: null }));
    if (isValidEmailAddress) {
      try {
        // Register the user and, if successful, log them in
        await app.emailPasswordAuth.registerUser(email, password);
        return await handleLogin();
      } catch (err) {
        handleAuthenticationError(err, setError);
      }
    } else {
      setError((err) => ({ ...err, email: "Email is invalid." }));
    }
  };

  return(<SignInForm>
    {/* <FormTitle>{mode === "login" ? "Log In" : "Register"}</FormTitle> */}
    <FormTitle>Precedent</FormTitle>
    <FormEmail
      type="email" 
      placeholder="Email" 
      onChange={(e) => {
        setError((e) => ({ ...e, email: null }));
        setEmail(e.target.value.toLowerCase());
      }}
      value={email}
      // state={
      //   error.email
      //     ? "error"
      //     : validator.isEmail(email)
      //     ? "valid"
      //     : "none"
      // }
      // errorMessage={error.email}
    />
    <FormPassword
      type="password" 
      placeholder="Password" 
      onChange={(e) => {
        setPassword(e.target.value);
      }}
    />
    <ErrorText>{error.email ? error.email: ""} {error.password ? error.password: ""}</ErrorText>
      {mode === "login" ? (
        <SignInButton variant="light" onClick={() => handleLogin()}>
          Log In
        </SignInButton>
      ) : (
        <SignInButton
          variant="light"
          onClick={() => handleRegistrationAndLogin()}
        >
          Register
        </SignInButton>
      )}
    <ToggleContainer>
      <span>
        {mode === "login"
        ? "Don't have an account?"
        : "Already have an account?"}
      </span>
      <a href="#"
        onClick={(e) => {
          e.preventDefault();
          toggleMode();
        }}
      >
        {mode === "login" ? "Register Now" : "Log In"}
      </a>
    </ToggleContainer>
    <ToggleContainer>
      <span>
        Forgot your password?
      </span>
      <Link to="/resetpassword">Click Here</Link>
    </ToggleContainer>
  </SignInForm>);

}

function handleAuthenticationError(err, setError) {
  const { status, message } = parseAuthenticationError(err);
  const errorType = message || status;
  console.log(status, message);
  switch (errorType) {
    case "invalid username":
      setError((prevErr) => ({ ...prevErr, email: "Invalid email address." }));
      break;
    case "invalid username/password":
    case "invalid password":
    case "401":
      setError((err) => ({ ...err, password: "Incorrect password." }));
      break;
    case "name already in use":
    case "409":
      setError((err) => ({ ...err, email: "Email is already registered." }));
      break;
    case "password must be between 6 and 128 characters":
    case "invalid token data":
      setError((err) => ({
        ...err,
        password: "Password reset link expired, request a new link",
      }));
      break;
    case "400":
      // If erver responds with 400, if could either indicate a non-conforming password
      // or it could also indicate an invalid license
      if (message === `400 - "password must be between 6 and 128 characters"`) {
        setError((err) => ({
          ...err,
          password: "Password must be between 6 and 128 characters.",
        }));        
      } else {
        setError((err) => ({
          ...err,
          password: "Send us an email to confirm your license",
        }));
      }
      break;
    default:
      console.log(errorType);
      console.log("Default Error");
      break;
  }
}

function parseAuthenticationError(err) {
  const parts = err.message.split(":");
  const reason = parts[parts.length - 1].trimStart();
  if (!reason) return { status: "", message: "" };
  const reasonRegex = /(?<message>.+)\s\(status (?<status>[0-9][0-9][0-9])/;
  const match = reason.match(reasonRegex);
  const { status, message } = match?.groups ?? {};
  return { status, message };
}

const SignInForm = styled(Form)`
  width: 100%;
  max-width: 550px;
  padding: 100px;
  margin: auto;
  height: 50vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  color: #000000;
  opacity: 0.95;
`;

const FormTitle = styled.h2`
  text-align: center;
  margin-bottom: 1em;
`;

const FormEmail = styled(Form.Control)`
  margin-bottom: 1em;
`;

const FormPassword = styled(Form.Control)`
  margin-bottom: 5px;
`;

const SignInButton = styled(Button)`
  width: 100%;
  margin-top: 1em;
`;

const ToggleContainer = styled.div`
  margin-top: 8px;
  font-size: 12px;
  display: flex;
  justify-content: center;
`;

const ErrorText = styled.span`
  font-size: 12px;
  align-items: left;
`;

// const ToggleText = styled.span`
//   line-height: 18px;
// `;

// const ToggleLink = styled.button`
//   background: none;
//   border: none;
//   margin-left: 10px;
// `;

const Container = styled.div`
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

// const LoginHeading = styled.h1`
//   margin: 0;
//   font-size: 32px;
// `;

// const LoginFormRow = styled.div`
//   margin-bottom: 16px;
// `;