import React from "react";
import Card from "react-bootstrap/Card"

export default function EntityHeaderCard({
  parentEntityName,
  parentEntityLongName,
  entityName, 
  entityLongName,
  entityPhase = ""
}) {
  return (
    // This card is closely related to the search results card, but it does not have
    // the ability to change the application state

    // TEXT LAYOUT
    // {parentName} - {parentLongName} ex. Project, Building, Space, etc
    // {entityName} {entityLongName} ex. Space, Building Story, Boundary/Interior Object
    // TODO: {entityPhase} ie. Phase of construction, indicator of time in design or construction process
      <Card className="mt-sm-4 border-0">
        <Card.Body>
          <small className="text-muted">{ parentEntityName ? `${parentEntityName} |` : ``} {parentEntityLongName}</small>
          { entityName === entityLongName ? <h3>{entityLongName}</h3> : <h3><span className="text-muted">{entityName}</span> {entityLongName}</h3>}
          <small>{entityPhase}</small>
        </Card.Body>
      </Card>
  );
}