import React from "react";
import Card from "react-bootstrap/Card";

export default function CommentDisplay({app, commentLimit, setCommentLimit, comments, setComments}) {

  return (
    <>
      {comments.length === commentLimit ?
        <a 
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setCommentLimit(commentLimit+5);
            // increaseCommentLimit(commentLimit, setCommentLimit);
          }} 
        >Show older comments</a> : null } {/* This shouldn't show if their aren't any comments or less comments than the limit */}
      <LatestComments
        app={app}
        comments={comments}
        setComments={setComments}
      />
    </>
  );
}

function LatestComments({app, comments, setComments}) {
  
  return (
    comments.map((comment, i) => (
      <Comment app={app} comment={comment} comments={comments} setComments={setComments} key={i} />
    ))
  );
}

function Comment({app, comment, comments, setComments, key}) {
  return (
    <Card className="mt-2 border-0">
      <CommentUser app={app} comment={comment} comments={comments} setComments={setComments} key={key} />
      <CommentText comment={comment} key={key} />
    </Card>
  );
}

function CommentUser({app, comment, comments, setComments}) {
  // returns a formatted span containing the user name of the commenter
  const username = comment.metadata.source.userName;
  if (app.currentUser.id === comment.metadata.source.userId) {
    // If the current user's ID matches the commenters ID, then give the commenter a link to delete the comment
    return (
      <small>
        {username} 
        <a href="" className="float-right"
          onClick={(e) => {
            e.preventDefault();
            deleteComment(app, comment._id.toString(), comments, setComments);
          }}> delete</a>
      </small>
    )
  } else {
    return (<small>{username}</small>);
  }
}

// function CommentPostTime() {
//   // returns the time that has elapsed from the user's current time since the time the comment was posted
//   return null;
// }

function CommentText({comment}) {
  return (
    comment.comment.text
  );
}

async function deleteComment(app, commentId, comments, setComments) {
  await app.currentUser.functions.deleteComment(commentId);
  const updatedComments = comments.filter(comment => !(comment._id.toString() === commentId)) // Remove the deleted comment from the comments array
  setComments(updatedComments) // Update the comments array
}