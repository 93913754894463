import React from "react";
import { ArcRotateCamera, Vector3, HemisphericLight, MeshBuilder, Color3, StandardMaterial} from "@babylonjs/core";
import { VertexData, Mesh } from "@babylonjs/core";
import SceneComponent from "./SceneComponent"; // uses above component in same directory
import * as earcut from "earcut";
import * as math from "mathjs";

export default function SceneTest({geoJson, modelSettings}) {

  const onSceneReady = (scene) => {

    scene.clearColor = new Color3( 1, 1, 1);
  
    var defaultMaterial = new StandardMaterial("mat1", scene);
    defaultMaterial.alpha = 0.25;
    defaultMaterial.backFaceCulling = false;
  
    // This creates and positions a free camera (non-mesh)
    // var camera = new FreeCamera("camera1", new Vector3(6, 10, -20), scene);
    var camera = new ArcRotateCamera("camera1", Math.PI / 4, Math.PI / 4, 30, Vector3.Zero(), scene);

    // This targets the camera to scene origin
    camera.setTarget(Vector3.Zero());
  
    const canvas = scene.getEngine().getRenderingCanvas();
  
    // This attaches the camera to the canvas
    camera.attachControl(canvas, true);
  
    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    var light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);

    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 0.7;
  
    geoJson.features.forEach((feature, i) => {
      
      if (feature.geometry.type === "Point") {
        handlePoint(feature, scene, defaultMaterial);
      }
      if (feature.geometry.type === "MultiPolygon") {
        handleMultiPolygon(feature, scene, defaultMaterial);  
      }
    });

    if (scene.meshes.length) {
      var worldExtends = scene.getWorldExtends();
     
      // Find center of worldExtends (all meshes)
      let center = worldExtends.min.add(worldExtends.max).divideInPlace(new Vector3(2,2,2));
      scene.activeCamera.target.copyFrom(center)

      // Fix camera angles.
      scene.activeCamera.alpha = scene.activeCamera.beta = Math.PI / 4;
      // scene.activeCamera.radius = Vector3.Distance(worldExtends.min, worldExtends.max)*3.5;
      const radius = Vector3.Distance(center, worldExtends.min);
      if (radius < 3) {
        scene.activeCamera.radius = Vector3.Distance(center, worldExtends.min)*6.5; 
      } else {
        scene.activeCamera.radius = Vector3.Distance(center, worldExtends.min)*3; 
      }
      
      // Calculate lowerRadiusLimit & add camera.minZ to avoid clipping.
      // scene.activeCamera.lowerRadiusLimit = Vector3.Distance(center, worldExtends.min) + scene.activeCamera.minZ;

      if (worldExtends.max._z > scene.activeCamera.maxZ) {
        scene.activeCamera.maxZ = (worldExtends.max._x > scene.activeCamera.radius ? worldExtends.max._x : scene.activeCamera.radius)*3.15;
      }

      if (worldExtends.min._z < scene.activeCamera.minZ) {
        console.log((worldExtends.min._x < scene.activeCamera.radius ? "World Min" : "Active Camera Radius"));
        console.log((worldExtends.min._x < scene.activeCamera.radius ? -worldExtends.min._x : -scene.activeCamera.radius)*3.15);
        scene.activeCamera.minZ = (worldExtends.min._x < scene.activeCamera.radius ? -worldExtends.min._x : -scene.activeCamera.radius)*3.15;
      }

      console.log("Center:", center, "World Extends | Min:", worldExtends.min, "Max:", worldExtends.max);
      console.log("Camera | Radius:", scene.activeCamera.radius, "Alpha:", scene.activeCamera.alpha, "Beta:", scene.activeCamera.beta);
      console.log("Camera X:", scene.activeCamera.minX, "-", scene.activeCamera.maxX);
      console.log("Camera Y:", scene.activeCamera.minY, "-", scene.activeCamera.maxY);
      console.log("Camera Z:", scene.activeCamera.minZ, "-", scene.activeCamera.maxZ);
    }
  };

  // {xrCompatible: false} // this is a hotfix for Safari 15 which caused the site to crash
  return (
    <SceneComponent antialias onSceneReady={onSceneReady} modelSettings={modelSettings} engineOptions={{"xrCompatible": false}} id="my-canvas" />
  );
}

function handlePoint(geoJson, scene, material=null) {
  let coordinates = geoJson.geometry.coordinates;
  let marker = MeshBuilder.CreateSphere("sphere", {diameter: .15, segments: 32}, scene);
  marker.position.x = coordinates[0];
  marker.position.y = coordinates[2];
  marker.position.z = coordinates[1];
  marker.material = material;
}

function handleMultiPolygon(geoJson, scene, material=null) {
  let cubeTest = {
    "vertex": [],
    "face": []
  };

  let coordinates = geoJson.geometry.coordinates;
  coordinates.forEach((polygon, i) => {
    if (polygon[0].length <= 4 && polygon.length === 1) {
      let polygonVertexIndices = [];
      polygon[0].forEach((point, k) => {
        let babylonPoint = [point[0], point[2], point[1]]; // Reorder coordinates to coorespond with BabylonJS coordinate order xyz -> xzy (y is up)
        if (!cubeTest["vertex"].includes(babylonPoint)) {
            cubeTest["vertex"].push(babylonPoint);
        }
        const vertexIndex = cubeTest["vertex"].indexOf(babylonPoint);
        polygonVertexIndices.push(vertexIndex);
      });
      cubeTest["face"].push(polygonVertexIndices);
    } else {

      // let data = earcut.flatten(polygon);
      const triangulationResults = triangulatePolygon(polygon);
      const data = triangulationResults['data'];
      const triangles = triangulationResults['triangles'];

      const faceVertexIndices = new Array(Math.ceil(triangles.length / 3))
        .fill()
        .map(_ => triangles.splice(0, 3));
      const faceVerticies = new Array(Math.ceil(data.vertices.length / 3))
        .fill()
        .map(_ => data.vertices.splice(0, 3));

      // console.log("Face Vertex Indicies", faceVertexIndices);
      faceVertexIndices.forEach((face) => {
        let polygonVertexIndices = [];
        face.forEach((index) => {
          const point = faceVerticies[index];
          let babylonPoint = [point[0], point[2], point[1]]; // Reorder coordinates to coorespond with BabylonJS coordinate order xyz -> xzy (y is up)
          if (!cubeTest["vertex"].includes(babylonPoint)) {
            cubeTest["vertex"].push(babylonPoint);
          }
          const vertexIndex = cubeTest["vertex"].indexOf(babylonPoint);
          polygonVertexIndices.push(vertexIndex);
        });
        // console.log(polygonVertexIndices);
        cubeTest["face"].push(polygonVertexIndices);
      });
    }
  });

  var box = createPolyhedron(cubeTest, 1, scene);
	box.material = material;

  return scene;
}

function triangulatePolygon(polygon) {
  // Flatten polygon
  let data = {
    "vertices": [],
    "reorderedVerticies": [],
    "holes": [],
    "dimensions": 3
  }

  // Calculate the indices of vertices that are the first vertice in a hole
  let boundLengths = [];  
  polygon.forEach((bound, i) => {
    if (i < polygon.length-1) {
      if (bound[0] !== bound[bound.length-1]) {
        data.holes.push(boundLengths.reduce((a, b) => a + b, 0) + bound.length);
        boundLengths.push(bound.length);
      } else {
        data.holes.push(boundLengths.reduce((a, b) => a + b, 0) + bound.length-1);
        boundLengths.push(bound.length-1);
      }
    }
  });

  // Compute normal vector of the polygon by computing the cross product of the first three points
  // This assumes all points on the polygon are on the same plane
  // TODO: This needs to be cleaned up. Right now it mixes syntax from BabylonJS with MathJS and it's going to be impossible to maintain
  
  // Ensure that the points used to calculate the normal accurately represent the plane of the polygon.
  // The following code checks that consecutive points added to the normalPoints array do not fall along the same line
  // There is probably a more robust way to calculate this, right now it only checks along the x, y and z planes.
  let normalPoints = [];

  for (let i=0; i<polygon[0].length; i++) {
    // if (normalPoints.length > 3) {
    //   console.log("Break");
    //   break;
    // } // only need 3 points to compute normal

    if (normalPoints.length === 0) {
      normalPoints.push(polygon[0][i]);
    } else {
      const pointIntersection = normalPoints[normalPoints.length-1].filter(value => polygon[0][i].includes(value));
      if (pointIntersection < 2) {
        normalPoints.push(polygon[0][i]);
      }
    }
  }

  // Fallback if the previous loop does not product 3 points
  if (normalPoints.length < 3) {
    normalPoints = polygon[0];
  }

  const vector1 = new Vector3(
    normalPoints[1][0], 
    normalPoints[1][1], 
    normalPoints[1][2]).subtract(
      new Vector3(
        normalPoints[0][0], 
        normalPoints[0][1], 
        normalPoints[0][2]
      )
    );
  const vector2 = new Vector3(
    normalPoints[2][0], 
    normalPoints[2][1], 
    normalPoints[2][2]).subtract(
      new Vector3(
        normalPoints[0][0], 
        normalPoints[0][1], 
        normalPoints[0][2]
      )
    );

  const vectorNormal = Vector3.Cross(vector1, vector2);
  vectorNormal.normalize() // Normalize vector for rotation
  const rotationMatrix = rotationMatrixFromVectors(vectorNormal, new Vector3(0, 0, 1)); // Returns a MathJS

  polygon.forEach((boundary) => {
    boundary.forEach((point) => {
      data.vertices.push(point[0]);
      data.vertices.push(point[1]);
      data.vertices.push(point[2]);

      if (Number.isNaN(rotationMatrix._data[0][0])) {
        data.reorderedVerticies.push(point[0]);
        data.reorderedVerticies.push(point[1]);
        data.reorderedVerticies.push(point[2]);
      } else {
        const rotatedPoint = math.multiply(rotationMatrix, math.matrix(point))._data;
        // console.log("Rotated Point", rotatedPoint);
        data.reorderedVerticies.push(rotatedPoint[0]);
        data.reorderedVerticies.push(rotatedPoint[1]);
        data.reorderedVerticies.push(rotatedPoint[2]);
      }
    })
  });
  let triangles = earcut(data.reorderedVerticies, data.holes, data.dimensions);
  console.log(triangles);
  return {
    'data': data,
    'triangles': triangles
  };
}

function createPolyhedron(data, size, scene) {
  var positions = [];
  var indices = [];
  var normals = [];
  var uvs = [];
  var face_uvs=[[0,0],[1,0],[1,1],[0,1]];

  // positions
  for (var i = 0; i < data.vertex.length; i++) {
    positions.push(data.vertex[i][0] * size, data.vertex[i][1] * size, data.vertex[i][2] * size);			  
  }

  // indices from faces		  
  for (var f = 0; f < data.face.length; f++) {
    for(var j = 0; j < data.face[f].length; j++) {
        uvs=uvs.concat(face_uvs[j]);
      }
    for (i = 0; i < data.face[f].length - 2; i++) {
      indices.push(data.face[f][0], data.face[f][i + 2], data.face[f][i + 1]);
    }
  }

  VertexData.ComputeNormals(positions, indices, normals);
  VertexData._ComputeSides(Mesh.FRONTSIDE, positions, indices, normals, uvs);

  var vertexData = new VertexData();
  vertexData.positions = positions;
  vertexData.indices = indices;
  vertexData.normals = normals;
  vertexData.uvs = uvs;

  var polygon = new Mesh("Polygon", scene);
  vertexData.applyToMesh(polygon);

  return polygon;
};

function rotationMatrixFromVectors(vec1, vec2) {
  // Find the rotation matrix that aligns vec1 to vec2
  // Input vectors must be normalized
  // vec1: A 3d "source" vector
  // vec2: A 3d "destination" vector
  // return mat: A transform matrix (3x3) which when applied to vec1, aligns it with vec2.

  const v = Vector3.Cross(vec1, vec2).asArray();
  const c = Vector3.Dot(vec1, vec2);
  const s = Vector3.Cross(vec1, vec2).normalize().asArray();

  // Translates the following equation written in Python to Javascript
  // rotation_matrix = np.eye(3) + kmat + kmat.dot(kmat) * ((1 - c) / (s ** 2))

  const id = math.matrix([[1, 0, 0], [0, 1, 0], [0, 0, 1]]); // Identity matrix
  const kmat = math.matrix([[0, -v[2], v[1]], [v[2], 0, -v[0]], [-v[1], v[0], 0]]);
  const kmatMultiply = math.multiply(kmat, kmat);

  const d = math.multiply(kmatMultiply, math.divide(1-c, math.multiply(math.matrix(s), math.matrix(s))));
  const rotationMatrix = math.add(math.add(id, kmat), d);
  // console.log("(rotation matrix: ", rotationMatrix);

  return rotationMatrix
}

