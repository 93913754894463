import React from "react";
import { useRealmApp } from "../../RealmApp";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import GridLoader from "react-spinners/GridLoader";
import styled from "@emotion/styled";
import { v4 as uuidv4 } from 'uuid';

export default function FileUploadScreen() {

  const app = useRealmApp();
  const currentUser = app.currentUser;
  const [name, setName] = React.useState("");

  // Project & Building Variables
  const [projectNumber, setProjectNumber] = React.useState("");
  const [projectName, setProjectName] = React.useState("");
  const [projectPhase, setProjectPhase] = React.useState("");
  const [practiceArea, setPracticeArea] = React.useState("");
  const [clientName, setClientName] = React.useState("");
  const [addressStreetFirstLine, setAddressStreetFirstLine] = React.useState("");
  const [addressStreetSecondLine, setAddressStreetSecondLine] = React.useState("");
  const [city, setCity] = React.useState("");
  const [region, setRegion] = React.useState("");
  const [country, setCountry] = React.useState("");
  

  // File Variables
  const [selectedFile, setSelectedFile] = React.useState("");
  const [uploadState, setUploadState] = React.useState({ 
    success : false,
    url : "",
    error: false,
    errorMessage : "",
    userMessage: ""
  }); // false if the file is not uploading, true if the file is uploading
  
  return (
    <Container className="mt-sm-4 mx-auto">
      <h1 className="mb-4">Upload a Model</h1>
      <Form>
        <Form.Group className="mx-3" controlId="formGridState">
          <Form.Row className="mt-3">
            {/* <Form.Label>Building Model File <i>(upload a .rvt or .ifc file)</i></Form.Label> */}
            <Form.Label>Building Model File <i>(upload a .ifc file)</i></Form.Label>
            <Form.File
              type="file"
              // className="custom-file-label"
              id="inputGroupFile01"
              label={selectedFile === "" ? "Choose a File" : selectedFile.name}
              onChange={(e) => handleFileSelection(e, selectedFile, setSelectedFile, uploadState, setUploadState)}
              custom
            />
          </Form.Row>
          <Form.Row className="mt-3">
              <Button 
                variant={selectedFile === "" ? "secondary" : "primary"} 
                className="w-100"
                onClick={(e) => submitProjectForm({e, 
                  currentUser, 
                  projectNumber,
                  projectName,
                  projectPhase,
                  practiceArea,
                  clientName,
                  addressStreetFirstLine,
                  addressStreetSecondLine,
                  city,
                  region,
                  country,
                  selectedFile, 
                  setSelectedFile, 
                  uploadState, 
                  setUploadState})}>
                {uploadState.userMessage === 'Upload in process, please wait' ? <GridLoader color="#f5efe0" size="5px" margin="5px" /> : 'Upload' }
              </Button>
              <UserMessage>{uploadState.userMessage}</UserMessage>
          </Form.Row>
        </Form.Group>
      </Form>
    </Container>
  );
}

function submitProjectForm({e, 
  currentUser, 
  selectedFile, 
  setSelectedFile, 
  uploadState, 
  setUploadState}) {

  handleFileUpload({e, currentUser, selectedFile, setSelectedFile, uploadState, setUploadState});
}

function handleFileSelection(e, selectedFile, setSelectedFile, uploadState, setUploadState) {
  // const formData = new FormData()
  // formData.append(e.target.files[0].name, e.target.files[0]);
  var re = /(?:\.([^.]+))?$/; // Regular expression to get the file extension
  const extension = re.exec(e.target.files[0].name)[1]; // ex. txt, csv, rvt, ifc
  // console.log(e.target.files[0].name, "->", e.target.files[0].name.hashCode());
  if (extension === "rvt" || extension === "ifc") {
    setSelectedFile({
      "name": e.target.files[0].name,
      "contents": e.target.files[0],
      "extension": extension
    })
    setUploadState({...uploadState, 'userMessage': ""})
  } else {
    // set error message telling user to only upload Revit files or IFC files
    console.log("User selected an invalid file type");
    setSelectedFile("");
    setUploadState({
      ...uploadState, 
      'userMessage': `We can't process ${extension} files, try another file`})
  }
}

// const handleChange = (ev) => {
//   setUploadState({success: false, url : ""});
// }

async function handleFileUpload({e, currentUser, selectedFile, setSelectedFile, uploadState, setUploadState}) {
  e.preventDefault();

  // Validate Form Info - Do not progress if form was not filled out correctly
  setUploadState({...uploadState, 'userMessage': "Upload in process, please wait"});
  try {
    let uploadURL = await currentUser.functions.getPresignedURL(`${selectedFile.extension}/${uuidv4()}.${selectedFile.extension}`, selectedFile.extension, selectedFile.name); // getPresignedURL(fileName/S3Key, fileType, originalFileName) from Realm 

    fetch(uploadURL, {method: "PUT", body: selectedFile.contents})
    .then((response) => {
      console.log(response);
      parseResponse(response, uploadState, setUploadState);
      setSelectedFile("");
      setUploadState({...uploadState, 'userMessage': `Successfully uploaded: ${selectedFile.name}`});
      // Call app function to insert a building document based on the form data
    });
  } catch (err) {
    setUploadState({...uploadState, 'success': false, 'error': true, 'userMessage': "We had a problem, please try again"});
  }
}

function parseResponse(response, uploadState, setUploadState) {
  if (response.status === 200) {
    setUploadState({
      success : false,
      url : response.url,
      error: false,
      errorMessage : ""
    })
  }
}

const Container = styled.div`
  width: 75%;
  padding: 15px;
  height: 90vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const UserMessage = styled.div`
  width: 100%;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;