import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import Image from "react-bootstrap/Image";
import HessGroupLogo from "../assets/img/hessgroup_logo.png";

export default function Footer() {
  return (
    <Container>
      <div className="w-75 row">
        <div className="col-6">
        <h3>Precedent</h3>
        <Link to="/privacypolicy">Privacy Policy</Link> <br />
        <Link to="/termsandconditions">Terms and Conditions</Link> <br /><br />
        </div>
        <div className="col-6">
          <br />
          <a href="https://www.hessgroup.io">
            <Image className="float-right" src={HessGroupLogo} />
          </a>
        </div>
        
      </div>
    </Container>
    );
}


const Container = styled.div`
height: 225px;
background-color: #f5efe0;
margin-top: 50px;
padding-top: 50px;
display: flex;
justify-content: center;
`;