import React, { useState} from 'react';
import MeshTest from "../viewer/MeshTest";
import Card from 'react-bootstrap/Card';
import { useRealmApp } from "../../RealmApp";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Dropdown } from 'react-bootstrap';
import BuildingStoryRender from '../viewer/BuildingStoryRender';
import Loading from '../Loading';
import Legend from '../viewer/Legend';

export default function GeoJsonViewerCard({objectId, containsElementTypes=[]}) {
  const app = useRealmApp();

  const defaultDisplayType = getDefaultDisplayType(containsElementTypes);
  const [modelSettings, setModelSettings] = React.useState({
    "type": defaultDisplayType,
    "visPropertyType": "annotations",
    "visProperty": "NameTag"
  });

  const [responseData, setResponseData] = React.useState("");

  React.useEffect(() => {
    app.currentUser.functions.getFeatureCollectionBuildingStory(objectId, modelSettings.type)
    .then((response) => {
      setResponseData(response);
    })
  }, [objectId, modelSettings.type]);

  if (responseData) {

    let visualizationList = {"annotations": [], "model": []};
    
    if (!responseData[0]) {
      return (
        <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
          <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
            Model
            <ToggleButtonExample 
              visualizationList={visualizationList}
              containsElementTypes={containsElementTypes}
              modelSettings={modelSettings}
              setModelSettings={setModelSettings}
            />
          </Card.Header>
          <Card.Body>
            Oops... We couldn't find any {modelSettings.type.toLowerCase()}s in the model.
          </Card.Body>
        </Card>
      );
    }
    console.log(responseData[0]);
    responseData[0].features.forEach((feature) => {
      if (feature.properties.visualization.annotations) {
        feature.properties.visualization.annotations.forEach((property) => {
          if (!visualizationList.annotations.includes(property.name)) {
            visualizationList.annotations.push(property.name);
          }
        });
      }
      if (feature.properties.visualization.model) {
        feature.properties.visualization.model.forEach((property) => {
          if (!visualizationList.model.includes(property.name)) {
            visualizationList.model.push(property.name);
          }
        });
      }
    });

    const visPropertyValues = getVisualizationPropertyRange({'geoJson': responseData[0], 'modelSettings': modelSettings});
    console.log("Model Settings:", modelSettings);
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
      <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
        Model
        <ToggleButtonExample 
          visualizationList={visualizationList}
          containsElementTypes={containsElementTypes}
          modelSettings={modelSettings}
          setModelSettings={setModelSettings}
        />
      </Card.Header>
        <BuildingStoryRender
          geoJson={responseData[0]}
          modelSettings={modelSettings}
          visPropertyValues={visPropertyValues}
        />
        <Legend visPropertyValues={visPropertyValues} />
      </Card>
    );
  } else {
    return (
      <Loading />
    );
  }
}

function ToggleButtonExample({visualizationList, containsElementTypes, modelSettings, setModelSettings}) {
  console.log("Contains Element Types:", containsElementTypes);


  // List of element types to include in the dropdown. This exists because there are some element types we don't typically have geometry for,
  // and it is a better UX to only display elements we know we have geometry for, rather than show the message "no geometry found"
  const includeElementTypes = ["Covering", "Door", "Slab", "Wall", "Window", "Column", "LightFixture", "Furniture", "BuildingElementProxy"];
  const displayOptions = getDisplayOptions(containsElementTypes, includeElementTypes);

  return (
    <ButtonGroup className="float-right">
      <DropdownButton
        className="border-0"
        size="sm"
        variant="light"
        title={modelSettings.type.replace(/([a-z])([A-Z])/g, '$1 $2').trim()}
      >
        {displayOptions.map((group) => (
          <>
            <Dropdown.Header className="text-muted">{group.header}</Dropdown.Header>
            <DisplayItems 
              displayItems={group.items} 
              containsElementTypes={containsElementTypes}
              modelSettings={modelSettings} 
              setModelSettings={setModelSettings} 
            />
          </>
        ))}
      </DropdownButton>


      <DropdownButton
        className="border-0"
        size="sm"
        variant="light"
        title={modelSettings.visProperty.replace(/([a-z])([A-Z])/g, '$1 $2').trim()}
      >
      <Dropdown.Header className="text-muted">Annotations</Dropdown.Header>
        {visualizationList.annotations.map((propertyName, i) => (
        <Dropdown.Item
          variant="light"
          key={i}
          value={propertyName}
          checked={modelSettings.visProperty === propertyName}
          onClick={(e) => setModelSettings({...modelSettings, "visPropertyType": "annotations", "visProperty": propertyName})}
        >{propertyName.replace(/([a-z])([A-Z])/g, '$1 $2').trim()}</Dropdown.Item>
      ))}
      <Dropdown.Header className="text-muted">Model Properties</Dropdown.Header>
      {visualizationList.model.map((propertyName, i) => (
        <Dropdown.Item 
          variant="light" 
          key={i}
          value={propertyName}
          checked={modelSettings.visProperty === propertyName}
          onClick={(e) => setModelSettings({...modelSettings, "visPropertyType": "model", "visProperty": propertyName})}
        >{propertyName.replace(/([a-z])([A-Z])/g, '$1 $2').trim()}</Dropdown.Item>
      ))}
      </DropdownButton>
    </ButtonGroup>
  );
}

function getDefaultDisplayType(containsElementTypes) {
  // List of element types to display when the page is loaded in order of priority
  const defaultHierarchy = ["Space", "Slab", "Wall", "Covering"];
  // defaultHierarchy.forEach((elementType) => {
  for (let i=0;i<defaultHierarchy.length;i++) {
    // Loop through each element type in the default hierarchy, and return the first one that is included in the containsElementTypes array
    if (containsElementTypes.includes(defaultHierarchy[i])) {
      console.log("Contains Element", defaultHierarchy[i]);
      return defaultHierarchy[i];
    }
  }
  // });

  return "";
}

function getDisplayOptions(containsElementTypes, includeElementTypes) {
  let displayOptions = [];
  if (containsElementTypes.includes("Space")) {
    displayOptions.push(
      {"header": "Spaces",
        "items": [
          {"label": "Spaces", "settings": {"type": "Space", "related": false}}
        ]
      }
    );
  }

  let objectsList = [];

  if (containsElementTypes.includes("Wall") || containsElementTypes.includes("WallStandardCase")) {
    objectsList.push(
      {"label": "Walls", "settings": {"type": "Wall"}, "related": false}
    );
  }

  if (containsElementTypes.includes("BuildingElementProxy")) {
    objectsList.push(
      {"label": "Other Objects", "settings": {"type": "BuildingElementProxy", "related": false}}
    );
  }

  containsElementTypes.forEach((elementType) => {
    if (!["Space", "Wall", "WallStandardCase", "BuildingElementProxy"].includes(elementType)) { //&& includeElementTypes.includes(elementType)) {
      const label = elementType.replace(/(\b[a-z](?!\s))/g, function(x){return x.toUpperCase();}); // Capitalize the first letter after every element
      objectsList.push(
        {"label": label, "settings": {"type": elementType, "related": false}}
      );
    }
  });

  if (objectsList.length > 1) {
    // sort objects alphabetically
    objectsList.sort((a, b) => (a.label > b.label) ? 1 : (a.label === b.label) ? ((a.size > b.size) ? 1 : -1) : -1 )
    displayOptions.push(
      {"header": "Objects",
        "items": objectsList
      }
    );
  }

  return displayOptions;
}

function DisplayItems({displayItems, containsElementTypes, modelSettings, setModelSettings}) {
  return (
    displayItems.map((item) => {
      if (containsElementTypes.includes(item.settings.type) || containsElementTypes.length === 0) {
        return (
          <Dropdown.Item
            varient="light"
            onClick={(e) => 
              setModelSettings({...modelSettings, "type": item.settings.type, "related": item.settings.related})
            }
          >{item.label}</Dropdown.Item>
        );
      }
      return null;
    })
  );
}

function getVisualizationList({geoJson}) {
  let visualizationList = [];

  geoJson.features.forEach((feature) => {
    feature.properties.visualization.forEach((property) => {
      visualizationList.push(property.name);
    });
  });

  visualizationList = new Set(visualizationList);
  return visualizationList;
}

function getVisualizationPropertyRange({geoJson, modelSettings}) {
  if (!modelSettings.visPropertyType || !modelSettings.visProperty) {
    return null;
  }
  let valueList = [];

  geoJson.features.forEach((feature) => {
    if (feature && feature.properties.visualization[modelSettings['visPropertyType']]) {
      feature.properties.visualization[modelSettings['visPropertyType']].forEach((property) => {
        if (property.name == [modelSettings['visProperty']] && !valueList.includes(property.value)) {
          valueList.push(property.value);
        }
      });
    }
  });
  return valueList;
}