import React from "react";
import { useRealmApp } from "../../RealmApp";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

export default function AnnotationCard({annotationDocument, key=0}) {
  const app = useRealmApp();

  const [displayState, setDisplayState] = React.useState("show"); // display state options: "show" or "edit"
  const [newValue, setNewValue] = React.useState(""); // used when a new address is entered

  const annotationName = annotationDocument.annotation.name;
  const formattedAnnotationName = annotationName.replace(/([a-z])([A-Z])/g, '$1 $2').trim(); // Put a space between lowercase and uppercase letters
  // let annotationType = null;
  let annotationValue = null;

  if (annotationDocument.annotation.text) {
    // annotationType = 'text';
    annotationValue = annotationDocument.annotation.text;
  }

  if (annotationDocument.annotation.value) {
    // annotationType = 'numeric'; // TODO: incorporate units
    annotationValue = annotationDocument.annotation.value;
  }

  if (displayState === "show") {
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100">
        <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
          {formattedAnnotationName}
          <Button
            variant="outline-secondary"
            // if an objectId is specified show the edit button, Without an objectId, the edit location annotation function won't know which document to update
            className="float-right border-0 visible"
            size="sm"
            onClick={(e) => {
              // e.preventDefault();
              setDisplayState("edit");
            }
          }>Edit</Button>
        </Card.Header>
        <h4>{newValue ? 
          newValue.replace(/(\b[a-z](?!\s))/g, function(x){return x.toUpperCase();})
           : annotationValue.replace(/(\b[a-z](?!\s))/g, function(x){return x.toUpperCase();})}</h4>
      </Card>
    );
  }

  if (displayState === "edit") {
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100">
      <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">
          {formattedAnnotationName}
          <Button
            variant="outline-secondary"
            className="float-right border-0" 
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              setDisplayState("show");
          }}>Edit</Button>
        </Card.Header>
        <Form className="mb-4">
          <Form.Group className="px-0 py-0 my-2">
            <Form.Label>Enter New Value | <small className="text-muted">(Current: {annotationValue})</small></Form.Label>
            <Form.Control 
              onChange={(e) => {setNewValue(e.target.value); console.log(newValue)}}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  changeValue(app, e, annotationDocument, newValue, displayState, setDisplayState);
                  annotationValue = newValue; // TODO: The annotation value should only change if the db update is successful
                }
              }}
            />
          </Form.Group>
          <Button
            size="sm"
            onClick={(e) => {
              changeValue(app, e, annotationDocument, newValue, displayState, setDisplayState);
              annotationValue = newValue; // TODO: The annotation value should only change if the db update is successful
            }}
          >Update</Button>
        </Form>
      </Card>
    );
  }
}


async function changeValue(
  app, e, annotationDocument, newValue, displayState, setDisplayState
) {
  e.preventDefault();
  await app.currentUser.functions.updateAnnotationText(annotationDocument._id.toString(), newValue);
  // TODO: Write code to show user that there was an error if a new location isn't found
  setDisplayState("show");
  return newValue; // only return the new value if the document is successfully updated
}