import React from "react";
import { Tooltip } from "react-bootstrap";
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer, Label } from "recharts";

export default function Histogram({bucketData, xAxisLabel="", yAxisLabel=""}) {
  let histogramData = [];

  bucketData.forEach((bucket, i) => {
    const bucketMinValue = Math.round(bucket._id.min*10)/10;
    const bucketMaxValue = Math.round(bucket._id.max*10)/10;
    const bucketSize = bucket.count
    let  bucketName = `${bucketMinValue}-${bucketMaxValue}`; // Bucket name is the range from minimum to maximum value (ex. "10-20")
    if (bucketMinValue === bucketMaxValue || bucketMinValue + 1 === bucketMaxValue ) {
      // If the minimum and maximum value in the bucket is the same, don't label it as a range, just use the minimum value
      // The other condition is to only use the minimum value if the maximum value is +1 from the minimum. This is done
      // because the domain of the bucket is [bucketMinValue, bucketMaxValue). The bucket does not actually contain the max value
      bucketName = `${bucketMinValue}`;
    }
    const bucketInfo = {'name': bucketName, 'count': bucketSize};
    histogramData.push(bucketInfo);
  });
  // console.log("HistogramData", histogramData);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <small className="text-muted">
        <BarChart width={250} height={100} data={histogramData}>
          <XAxis dataKey="name">
            <Label value={xAxisLabel} position="insideBottom" offset={0} />
          </XAxis>
          <YAxis>
            <Label value={yAxisLabel} position="outsideBottomRight" offset={1} angle={270} />
          </YAxis>
          <Bar dataKey="count" fill="#79BDED" />
        </BarChart>
      </small>
    </ResponsiveContainer>
  );
}