import React from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card"

export default function SearchResultCard({
  parentEntityName,
  parentEntityLongName,
  entityId,
  entityCollection,
  entityName, 
  entityLongName,
  entityPhase = ""
}) {
  if (entityName === entityLongName) {
    entityName = "";
  }

  if (entityCollection) {
    let collectionLink = entityCollection.replace("entity", "").toLowerCase(); // transform collection name to link name (ex. entitySpace -> space)
    return (
      // EntityId used to link to entity page
      // TEXT LAYOUT
      // {parentName} - {parentLongName} ex. Project, Building, Space, etc
      // {entityName} {entityLongName} ex. Space, Building Story, Boundary/Interior Object
      // TODO: {entityPhase} ie. Phase of construction, indicator of time in design or construction process
      <Link to={`/${collectionLink}/${entityId}`}> 
        <Card className="w-75 mx-auto mt-sm-4 border-left-0 border-top-0 border-right-0">
          <Card.Body>
            <small className="text-muted">{ parentEntityName ? `${parentEntityName} |` : ``} {parentEntityLongName}</small>
            <h5><span className="text-muted">{entityName}</span> {entityLongName}</h5>
            {/* <small>{entityCollection} {entityId}</small> */}
          </Card.Body>
        </Card>
      </Link>
    );
  } else {
    return (
    <Card className="w-75 mx-auto mt-sm-4 border-left-0 border-top-0 border-right-0">
      <Card.Body>
        <small className="text-muted">{ parentEntityName ? `${parentEntityName} |` : ``} {parentEntityLongName}</small>
        <h5><span className="text-muted">{entityName}</span> {entityLongName}</h5>
        {/* <small>{entityCollection} {entityId}</small> */}
      </Card.Body>
    </Card>
    );
  }
}