import React from "react";
import { Switch, Route } from "react-router-dom";
import styled from "@emotion/styled";
import SearchScreen from "./screens/SearchScreen";
import BuildingScreen from "./screens/BuildingScreen";
import BuildingStoryScreen from "./screens/BuildingStoryScreen";
import SpaceScreen from "./screens/SpaceScreen";
import GroupScreen from "./screens/GroupScreen";  
import BoundaryEntityScreen from "./screens/BoundaryEntityScreen";
import InteriorEntityScreen from "./screens/InteriorEntityScreen";
import UserProfileScreen from "./screens/UserProfileScreen";
import FileUploadScreen from "./screens/FileUploadScreen";
import HomeScreen from "./screens/HomeScreen";
import DocumentsByAnnotationScreen from "./screens/DocumentsByAnnotationScreen";
import SupportScreen from "./screens/SupportScreen";
import PrivacyPolicy from "./legal/PrivacyPolicy";
import TermsAndConditions from "./legal/TermsAndConditions";

export default function AppDisplay({
  functionalProps,
  setFunctionalProps
}) {
  return updateDisplay({functionalProps, setFunctionalProps});
}

function updateDisplay({functionalProps, setFunctionalProps}) {
  return (
    <DisplaySwitch 
      functionalProps={functionalProps}
      setFunctionalProps={setFunctionalProps}
    />
  );
}


function DisplaySwitch({
  functionalProps,
  setFunctionalProps
}) {
  /*
  Routes

  SEARCH ROUTES
  /search - SearchScreen

  BUILDING MODEL ROUTES
  /boundary - BoundaryScreen
  /building - BuildingScreen
  /buildingstory - BuildingStoryScreen
  /interior - InteriorEntityScreen
  /space - SpaceScreen

  APP MANAGEMENT ROUTES
  /profile - UserProfileScreen
  /upload - FileUploadScreen
  / Home
  */

  return (
    <Container>
      <Switch>
        <Route path="/search/:searchString">
          <SearchScreen />
        </Route>
        <Route path="/boundary/:id/:p?">
          <BoundaryEntityScreen />
        </Route>
        <Route path="/building/:id/:p?">
          <BuildingScreen />
        </Route>
        <Route path="/buildingstory/:id/:p?">
          <BuildingStoryScreen />
        </Route>
        <Route path="/interior/:id/:p?">
          <InteriorEntityScreen />
        </Route>
        <Route path="/space/:id/:p?">
          <SpaceScreen />
        </Route>
        <Route path="/group/:id">
          <GroupScreen />
        </Route>
        <Route path="/annotation/:name/:value">
          <DocumentsByAnnotationScreen />
        </Route>
        <Route path="/profile">
          <UserProfileScreen />
        </Route>
        <Route path="/upload">
          <FileUploadScreen />
        </Route>
        <Route path="/support">
          <SupportScreen />
        </Route>
        <Route path="/privacypolicy">
          <PrivacyPolicy />
        </Route>
        <Route path="/termsandconditions">
          <TermsAndConditions />
        </Route>
        <Route path="/">
          <HomeScreen />
        </Route>
        <Route path="*"> {/* No Match -> Default Screen */}
          <HomeScreen />
        </Route>
      </Switch>
    </Container>
  );
}

const Container = styled.div`
  min-height: 90vh;
`;