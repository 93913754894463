import React from "react";
import { useRealmApp } from "../../RealmApp";
import Card from "react-bootstrap/Card";
import DonutChart from "../graphs/DonutChart";

export default function RadarChartCard({annotationName}) {
  // The adjacencyDefinitionObject is they object type in the database that defines whether or not two spaces are adjacent
  const app = useRealmApp();
  const [responseData, setResponseData] = React.useState("");

  React.useEffect(() => {
    app.currentUser.functions.getAnnotationNameCount(annotationName)
    .then((response) => {
      setResponseData(response);
    })
  }, [annotationName]);

  if (responseData) {
    responseData.forEach((response) => {
      response.annotationName = annotationName
    })
    const formattedAnnotationName = annotationName.replace(/([a-z])([A-Z])/g, '$1 $2').trim(); // Put a space between lowercase and uppercase letters
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100">
        <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">{formattedAnnotationName} Distribution</Card.Header>
        <DonutChart
          data={responseData}
        />
      </Card>
    );
  } else {
    const formattedAnnotationName = annotationName.replace(/([a-z])([A-Z])/g, '$1 $2').trim(); // Put a space between lowercase and uppercase letters

    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100">
        <Card.Header className="bg-white border-0 px-0 py-1 font-weight-bold">{formattedAnnotationName}</Card.Header>
        <DonutChart
          data={responseData}
        />
      </Card>
    );
  }
}