import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useRealmApp } from "../../RealmApp";
import ErrorBoundary from "../ErrorBoundary";
import StatsCardNumericAnnotation from "../cards/StatsCardNumericAnnotation";
import StatsCardGroupTypeCount from "../cards/StatsCardGroupTypeCount";
import RadarChartCard from "../cards/RadarChartCard";
import CommentCard from "../cards/CommentsCard";
import GroupDocumentsCard from "../cards/GroupDocumentsCard";
import MapCard from "../cards/MapCard";


export default function GroupScreen() {
  const app = useRealmApp();
  const params = useParams();
  if (params.id) {
    var props = {
      "groupId": params.id
    }
  }

  return (
    <div className="w-75 mx-auto mt-4">
      <div className="row">
        <div className="col-3">
          <StatsCardNumericAnnotation groupId={props.groupId} />
          <RadarChartCard groupName={props.groupId} adjacencyDefinitionObject='Door' label='Shared Doors' />
          <RadarChartCard groupName={props.groupId} adjacencyDefinitionObject='Wall' label='Shared Walls' />
          <StatsCardGroupTypeCount groupName={props.groupId} objectType='Window' />
          <StatsCardGroupTypeCount groupName={props.groupId} objectType='Door' />
        </div>
        <div className="col-6">
          <GroupDocumentsCard groupName={props.groupId} documentType="search" />
          {/* <GroupName string={groupDocument.results.name} /> */}
          {/* <MapCard objectId={""} /> */}
        </div>
        <div className="col-3">
          {/* <CommentCard objectId={props.groupId} collectionName="annotationGroup" /> */}
        </div>
      </div>
    </div>
  );

//   return (
//     <div>
//       <ErrorBoundary>
//         <ApiResults 
//           app={app}
//           props={props}
//         />
//       </ErrorBoundary>
//     </div>
//   );
}

