import React from "react";
import { useRealmApp } from "../../RealmApp";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import styled from "@emotion/styled";


export default function UserProfileScreen() {
  const app = useRealmApp();
  const currentUser = app.currentUser;

  const [firstName, setFirstName] = React.useState(currentUser.customData.custom_data.firstName);
  const [lastName, setLastName] = React.useState(currentUser.customData.custom_data.lastName);
  const [city, setCity] = React.useState(currentUser.customData.custom_data.city);
  const [state, setState] = React.useState(currentUser.customData.custom_data.state);
  const [zip, setZip] = React.useState(currentUser.customData.custom_data.zip);
  const [userMessage, setUserMessage] = React.useState("");

  return (
    <Container className="mt-sm-4 mx-auto">
      <h1>Tell us about yourself...</h1>
      <Form className="mt-sm-4">
      <Form.Row>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>First Name</Form.Label>
          <Form.Control value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridPassword">
          <Form.Label>Last Name</Form.Label>
          <Form.Control value={lastName} onChange={(e) => setLastName(e.target.value)}/>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>City</Form.Label>
          <Form.Control value={city} onChange={(e) => setCity(e.target.value)}/>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
        <Form.Label>State</Form.Label>
          <Form.Control value={state} onChange={(e) => setState(e.target.value)}/>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label>ZIP Code</Form.Label>
          <Form.Control value={zip} onChange={(e) => setZip(e.target.value)}/>
        </Form.Group>
      </Form.Row>

      {/* <Form.Group id="formGridCheckbox">
        <Form.Check type="checkbox" label="Sign up for our newsletter" />
      </Form.Group> */}

      <Button variant="primary" onClick={() => submitUserInfo(
        app,
        userMessage,
        setUserMessage,
        firstName,
        lastName,
        city,
        state,
        zip
      )}>
        Submit
      </Button>
      <UserMessage>{userMessage}</UserMessage>
      </Form>
    </Container>
  );
}

async function submitUserInfo(app, userMessage, setUserMessage, firstName, lastName, city, state, zip) {
  try {
    // Refresh the user's local customData property
    await app.currentUser.functions.setProfileData({
      "firstName": firstName,
      "lastName": lastName,
      "city": city,
      "state": state,
      "zip": zip
    });

    app.currentUser.refreshCustomData();
    setUserMessage("Successfully updated profile");
  } catch (err) {
    setUserMessage("We had a problem... try again")
  }
}

const Container = styled.div`
  width: 65%;
  padding: 15px;
  height: 75vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const UserMessage = styled.span`
  padding-left: 1.5em;
  margin-top: 15px;
  font-style: italic;
`;