import React from "react";
import { useRealmApp } from "../../RealmApp";
import Card from "react-bootstrap/Card";
import Loading from "../Loading";
import { Link } from 'react-router-dom';

export default function GroupDocumentsCard({groupName, documentType}) {
  const app = useRealmApp();
  const [responseData, setResponseData] = React.useState("");

  React.useEffect(() => {
    // app.currentUser.functions.getGroupDocuments(groupId, documentType)
    app.currentUser.functions.getAnnotationDocuments(
      'entitySpace',
      'NameTag',
      groupName, 
      documentType
    )
    .then((response) => {
      setResponseData(response);
    })
  }, [app.currentUser.functions, groupName, documentType]);

  if (responseData) {
    console.log("ResponseData", responseData);
    if (responseData[0]) {
      return (
        <>
          <GroupName name={groupName} />
          <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100">
            <GroupObjects objects={responseData[0].objects} />
          </Card>
        </>
      );
    } else {
      return (
        <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100">
          Could not find any objects in this group, <Link to="/">Return to the homescreen</Link>
        </Card>
      )
    }
  } else {
    return (
      <Card className="mt-1 border-bottom-0 border-right-0 border-left-0 w-100">
        <Loading />
      </Card>
    );
  }
}

function GroupName({name}) {
  // Formats the group name to capitalize characters at the beginning of the string and after spaces
  if (!name) {
    return null;
  }

  const formattedName = name.replace(/(\b[a-z](?!\s))/g, function(x){return x.toUpperCase();}); // Capitalize the first letter after every space
  return (
    <h2 className="mt-1">
      {formattedName}
    </h2>
  )
}

function GroupObjects({objects}) {
  return (
    objects.map((object, i) => (
      <ObjectSummaryCard
        object={object} 
        key={i}
        />
    ))
  );
}

function ObjectSummaryCard({object, key}) {
  let objectName = object.entityName;
  if (objectName === object.entityLongName) {
    objectName = "";
  }
  let collectionLink = object.collection.replace("entity", "").toLowerCase(); // transform collection name to link name (ex. objectSpace -> space)
  return (
    <Link to={`/${collectionLink}/${object.objectId}`}> 
      <Card className="mx-0 mt-1 border-left-0 border-top-0 border-right-0">
        <Card.Body className="p-1">
          <small className="text-muted">{object.parentName} | {object.parentLongName}</small>
          <h5><span className="text-muted">{objectName}</span> {object.entityLongName}</h5>
        </Card.Body>
      </Card>
    </Link>
  );
}