import React from "react";
import Card from "react-bootstrap/Card";
import Map from "../maps/Map";

export default function ExperienceCard() {
  /*
  This card is to be displayed on the homepage as a way to show users the experiences of their firm, and the
  models that have been uploaded to Precedent. The main feature is the map that shows users where each of the buildings
  they have uploaded are located. Users can then click on any of the pins to go to that building. This serves as an easy
  entry point to exploring the data and helps marketing staff who many not be able to put a map like this together quickly.
  */

  return (
    <Card className="mt-1 py-2 border-bottom-0 border-right-0 border-left-0 w-100 rounded-0">
      <div className="row">
        <div className="col-3">
          <h3>Experience</h3>
          <p>
          The experience map shows all of the project locations of the uploaded models. 
          Click on any one of the markers to view to navigate to the building page. 
          On the building page you can drill down into the model, and get a street, satellite or topography map of the site.
          </p>
        </div>
        <div className="col-9">
          <Map />
        </div>
      </div>
    </Card>
  );
}